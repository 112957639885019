import { GridColDef } from '@mui/x-data-grid';

import { getColumnValue } from 'hooks/useDataGridHelpers';

export const TOAST_ERROR_MESSAGE = 'Unexpected error occurred.';

export const ACCESS_TOKEN = 'access_token';
export const BEARER_TOKEN = 'Bearer';

export const EN_LANGUAGE = 'en';

export const SPACE_BETWEEN = 'space-between';
export const END = 'end';

export const ID = 'id';

export const MIN_ALLOWED_CHARACTERS_PASSWORD = '4';
export const MIN_ALLOWED_CHARACTERS_NAME = '1';
export const MIN_ALLOWED_VALUE = '1';
export const MAX_ALLOWED_CHARACTERS = '100';

export const COLUMNS_DEFAULT_OPTIONS: Partial<GridColDef> = {
  flex: 1,
  align: 'left',
  headerAlign: 'left',
  valueGetter: getColumnValue,
  disableColumnMenu: true
};
export const DEFAULT_ROWS_PER_PAGE_OPTIONS = [2, 5, 10, 18, 25, 100];
export const DEFAULT_PAGINATION_MODEL = {
  pageSize: 18,
  page: 0
};
export const DEFAULT_GRID_ROW_HEIGHT = 40;

export const ID_PARAMETER = ':id';
export const COMPANY_PARAMETER = ':companyId';
export const BANK_STATEMENT_PARAMETER = ':bankStatementId';

export const SHORT_COMPANY_NAME = 'shortNameCompany';
export const EXPORTABLE = 'exportable';
export const COUNTER_PARTY_NAME = 'counterpartyName';
export const INVOICE_NUMBER = 'invoiceNumber';
export const INVOICE_DATE = 'invoiceDate';
export const INVOICE_AMOUNT = 'invoiceAmount';
export const CURRENCY = 'currency';
export const DUE_DATE = 'dueDate';
export const VAT_AMOUNT = 'vatAmount';
export const EXPENSE_TYPE = 'expenseType';
export const STAGE = 'stage';
export const DOCUMENT_TYPE = 'documentType';
export const INSERTED_BY = 'insertedBy';
export const INSERTED_AT = 'insertedAt';
export const COMPANY_IDS = 'companyIds';

export const BANK_STATEMENT_BANK_NAME = 'bankName';
export const BANK_STATEMENT_NUMBER_FIELD = 'bankStatementNumber';
export const BANK_STATEMENT_PERIOD = 'period';
export const BANK_STATEMENT_COMPANY = 'company';

export const TRANSACTION_NUMBER_FIELD = 'bankStatementNumber';
export const TRANSACTION_COMPANY_NAME = 'companyName';
export const TRANSACTION_VALUE_DATE = 'valueDate';
export const TRANSACTION_REASON = 'reason';
export const TRANSACTION_REASON_TYPE = 'reasonType';
export const TRANSACTION_COUNTER_PARTY_NAME = 'counterpartyName';
export const TRANSACTION_CREDIT = 'credit';
export const TRANSACTION_DEBIT = 'debit';
export const TRANSACTION_IBAN = 'iban';
export const TRANSACTION_INVOICE_NUMBER = 'invoiceNumber';
export const TRANSACTION_VREDIT_NOTICE = 'creditNotice';
export const TRANSACTION_COMPANY_REGISTRATION_NUMBER =
  'companyRegistrationNumber';
export const TRANSACTION_ACCOUNT_NUMBER = 'accountNumber';

export const MONTH_YEAR = 'Month/Year';
export const ORIGIN_BANK = 'Origin bank';
export const BANK_STATEMENT_NUMBER = 'Bank statement number';
export const ACTIONS = 'Actions';
export const TRUSTED = 'Trusted';

export const DRAFT_INVOICE_STYLE = 'draftInvoiceStyle';
export const ACCOUNTED_INVOICE_STYLE = 'accountedInvoiceStyle';
export const CHECK = '__check__';

export const NULL = null;
export const ZERO = 0;
export const ONE = 1;

export const BUTTON = 'button';

export const INVOICES = 'invoices';
export const INVOICE = 'invoice';
export const BANK_STATEMENTS = 'bank-statements';
export const SETTINGS = 'settings';
export const PROMPT_SETTINGS = 'prompt-settings';
export const COMPANIES_SETTINGS = 'companies-settings';
export const COMPANIES = 'companies';
export const ALL = 'all';

export const CHART_OF_ACCOUNTS = 'chart-of-accounts';
export const COUNTER_PARTIES = 'counterparties';
export const DETAILS = 'details';
export const EXPENSE_TYPES = 'expense-types';
export const BUDGETING = 'budgeting';
export const APPROVERS = 'approvers';
export const IS_ALREADY_PAID = 'isAlreadyPaid';
export const PAYROLL = 'payroll';
export const EXPENSE_TYPE_NAME = 'expenseTypeName';
export const IBANS_LIST = 'ibansList';
export const ACTIVE = 'active';

export const TOKEN_EXPIRY_WARNING_TIME_SECONDS = 30;

export const DEFAULT_COMPANY_REGISTRATION_NUMBER = '999999999999999';

export const PDF_CONTENT_TYPE = 'application/pdf';
export const PDF_JS_WORKER_URL =
  'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.4.120/pdf.worker.min.js';

export const EUROBANK_CODE = 'BPBI';

export const LANGUAGE_TO_LOCALE: Record<string, string> = {
  en: 'enUS',
  bg: 'bgBG'
};

export const DATE_FORMATS = {
  displayedDateFormat: 'DD.MM.YYYY',
  monthTextFormat: 'MMMM',
  monthTextAndYearFormat: 'MMMM YYYY',
  shortMonthFormat: 'MMM'
};

export const AUTO_APPROVAL_THRESHOLD_MAX_LENGTH = 10;

export const REGEX_MONEY_FORMAT_SPACING = /\B(?=(\d{3})+(?!\d))/g;
export const REGEX_SUM_OF_MONEY = /^(\d+)?(\.\d{0,2})?$/;
export const REGEX_DECIMAL_NUMBERS = /^0(?![0-9])|^[1-9]\d*(\.\d{0,2})?$/;

export const MONTHS = new Array(12).fill(0).map((_, index) => index + 1);

export const INITIAL_IBAN_ITEM = {
  iban: ''
};
export const COUNTERPARTY_IBAN_ADDITIONAL_INITIAL_FIELDS = {
  isVerified: false,
  isUsed: false
};

export const CHARTS_OTHERS_MAX_INDEX = 10;
export const CHARTS_LABEL_MAX_CHARS = 12;
export const CHARTS_LABEL_MAX_CHARS_WITHOUT_ELIPSIS = 13;
export const PIE_CHART_LABELS_MAX_CHARS = 20;

export const INTEGER_INPUT_MAX_LENGTH = 9;

export const COUNTERPARTY_BG_REG_NUMBER_LENGTH_REGEX = /^\d{9,13}$/;
export const COUNTERPARTY_MIN_LENGTH = 9;
export const COUNTERPARTY_MAX_LENGTH = 13;

export const AMOUNT_MIN = 1;
export const AMOUNT_MAX = 999999999;

export const HEARTBEAT_INTERVAL = 3000;
export const HEARTBEAT_TIMEOUT = HEARTBEAT_INTERVAL + 5000;
