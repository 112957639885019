/* eslint-disable react/no-array-index-key */
import { Checklist } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import FilterListIcon from '@mui/icons-material/FilterList';
import PaymentIcon from '@mui/icons-material/Payment';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import {
  Paper,
  Box,
  Button,
  Stack,
  Skeleton,
  Tooltip,
  CircularProgress,
  Chip
} from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
  GridValidRowModel,
  GridRowClassNameParams,
  GridCellParams,
  GridPaginationModel,
  GridSortModel,
  GridActionsCellItem
} from '@mui/x-data-grid';
import { useAtom } from 'jotai';
import {
  Dispatch,
  Key,
  SetStateAction,
  useCallback,
  useMemo,
  useState
} from 'react';
import { useDropzone } from 'react-dropzone';
import { useNavigate, useParams } from 'react-router-dom';

import { useExportController } from 'api/controllers/ExportController';
import { useInvoiceController } from 'api/controllers/InvoiceController';

import {
  CompanyIban,
  Invoice,
  InvoiceFilters,
  InvoiceQueryFilters,
  InvoiceSortColumns,
  InvoiceStages
} from 'openapi';

import { SelectIbanForm } from 'components/forms/SelectIbanForm';
import { OverlayDropFiles } from 'components/overlays/OverlayDropFiles';
import { OverlayLoading } from 'components/overlays/OverlayLoading';
import { CustomGridFooter } from 'components/shared/CustomGridFooter/CustomGridFooter';
import { GridToolbar } from 'components/shared/GridToolbar/GridToolbar';
import { ConfirmationDialog } from 'components/shared/Modal/ConfirmationDialog';
import { Modal } from 'components/shared/Modal/Modal';
import { SeverityPill } from 'components/shared/SeverityPill/SeverityPill';

import { usePermissions } from 'context/PermissionsContext';
import { useTranslations } from 'context/TranslationContext';

import { useDataGridHelpers } from 'hooks/useDataGridHelpers';
import { useModal } from 'hooks/useModal';

import {
  CHECK,
  COMPANY_PARAMETER,
  ID_PARAMETER,
  ACTIONS,
  APPROVERS,
  DEFAULT_GRID_ROW_HEIGHT,
  ACCOUNTED_INVOICE_STYLE,
  IS_ALREADY_PAID
} from 'utils/constants/constants';
import { INVOICES_FILTERS_EXPANDED_HEIGHT } from 'utils/constants/filters';
import { FilterType } from 'utils/enums/FilterType';
import { downloadZippedInvoices } from 'utils/helpers/downloadZippedInvoices';
import { exportTxtFile } from 'utils/helpers/exportTxtFile';
import {
  getInvoiceColumns,
  getInvoiceColumnsWithSkeleton,
  getContainerHeight
} from 'utils/helpers/invoiceHelpers';
import { formatMoney } from 'utils/helpers/moneyHelper';
import {
  getUnsupportedFormatMessage,
  showWarnMessage
} from 'utils/helpers/toastHelpers';
import { ExportForPaymentForm } from 'utils/interfaces/ExportForPaymentForm';
import { InvoicesFiltersForm } from 'utils/interfaces/InvoiceProps';
import { AppRoutesEnum } from 'utils/routes';

import { commonDataGridContainerStyle } from 'styles/components/DataGridStyle';
import { invoicesDataGrid } from 'styles/components/InvoicesDataGridStyle';

import { areFiltrsVisibleAtom, filterTypeAtom, userAtom } from 'state/state';

import { InvoicesFilterPanel } from './InvoicesFilterPanel';

interface InvoicesDataGridProp {
  readonly invoicesList: Invoice[];
  readonly filters: InvoiceQueryFilters;
  readonly stateRoute: string;
  readonly handleUploadInvoice?: (
    fileData: FileList | File[],
    field: string
  ) => Promise<void>;
  readonly setInvoicesList: Dispatch<SetStateAction<Invoice[]>>;
  readonly invoicesFiltersData?: InvoiceFilters;
  readonly handleApplyFilters: (data: InvoicesFiltersForm | null) => void;
  readonly handlePaginationModelChange: (
    newPaginationModel: GridPaginationModel
  ) => void;
  readonly paginationModel: GridPaginationModel;
  readonly rowCount: number;
  readonly handleSortModelChange: (sortModel: GridSortModel) => void;
  readonly fetchInvoices: () => void;
  readonly isLoading: boolean;
  readonly companyIbans?: CompanyIban[];
}

export const InvoicesDataGrid = ({
  invoicesList,
  filters,
  stateRoute,
  handleUploadInvoice,
  setInvoicesList,
  invoicesFiltersData,
  handleApplyFilters,
  handlePaginationModelChange,
  rowCount,
  paginationModel,
  handleSortModelChange,
  fetchInvoices,
  isLoading,
  companyIbans
}: InvoicesDataGridProp) => {
  const navigate = useNavigate();
  const { companyId } = useParams();

  const [filterType] = useAtom(filterTypeAtom);
  const isWithoutQuickFilter = useMemo(
    () => filterType === FilterType.Initial,
    [filterType]
  );

  const { permissions } = usePermissions();
  const { translate } = useTranslations();

  const [user] = useAtom(userAtom);

  const {
    isOpen: isDeleteModalOpen,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal
  } = useModal();
  const {
    isOpen: isAccountModalOpen,
    openModal: openAccountModal,
    closeModal: closeAccountModal
  } = useModal();
  const {
    isOpen: isPayModalOpen,
    openModal: openPayModal,
    closeModal: closePayModal
  } = useModal();
  const {
    isOpen: isApproveModalOpen,
    openModal: openApproveModal,
    closeModal: closeApproveModal
  } = useModal();
  const {
    isOpen: isRejectModalOpen,
    openModal: openRejectModal,
    closeModal: closeRejectModal
  } = useModal();
  const {
    isOpen: isExportForPaymentModalOpen,
    openModal: openExportForPaymentModal,
    closeModal: closeExportForPaymentModal
  } = useModal();

  const { getSeverityColor } = useDataGridHelpers();
  const {
    deleteInvoices,
    setIsAccounted,
    payInvoicesByIds,
    approveInvoicesByIds,
    rejectInvoicesByIds,
    createWithoutDocument
  } = useInvoiceController();
  const {
    exportInvoicesPayment,
    exportInvoicesZipByIds,
    exportInvoicesExcelByFilters,
    exportInvoicesExcelByCompanyIdAndFilters
  } = useExportController();

  localStorage.setItem('state', stateRoute);

  const [areFiltersVisible, setAreFiltersVisible] =
    useAtom(areFiltrsVisibleAtom);

  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [selectedSingleInvoiceId, setSelectedSingleInvoiceId] =
    useState<number>();
  const [isProcessingDownload, setIsProcessingDownload] =
    useState<boolean>(false);

  const handleCloseModals = () => {
    closeDeleteModal();
    closeAccountModal();
    closePayModal();
    closeApproveModal();
    closeRejectModal();
    closeExportForPaymentModal();
    setSelectedRows([]);
    setSelectedSingleInvoiceId(undefined);
  };

  const confirmDelete = async () => {
    handleCloseModals();
    await deleteInvoices({
      invoiceIds: selectedSingleInvoiceId
        ? [selectedSingleInvoiceId]
        : selectedRows
    });

    const updatedInvoiceList = invoicesList.filter(
      (invoice) =>
        !selectedRows.includes(invoice.id as number) ||
        invoice.id !== selectedSingleInvoiceId
    );
    setInvoicesList(updatedInvoiceList);
  };

  const confirmAccount = async () => {
    handleCloseModals();
    await setIsAccounted(
      selectedSingleInvoiceId ? [selectedSingleInvoiceId] : selectedRows
    );

    const updatedInvoiceList = invoicesList.map((invoice) => {
      if (
        selectedRows.includes(invoice.id as number) ||
        invoice.id === selectedSingleInvoiceId
      ) {
        return {
          ...invoice,
          isAccounted: true
        };
      }
      return invoice;
    });
    setInvoicesList(updatedInvoiceList);
    fetchInvoices();
  };

  const confirmPay = async () => {
    handleCloseModals();
    await payInvoicesByIds(
      selectedSingleInvoiceId ? [selectedSingleInvoiceId] : selectedRows
    );

    const updatedInvoiceList = invoicesList.map((invoice) => {
      if (
        selectedRows.includes(invoice.id as number) ||
        invoice.id === selectedSingleInvoiceId
      ) {
        return {
          ...invoice,
          stage: InvoiceStages.PAID
        };
      }
      return invoice;
    });
    setInvoicesList(updatedInvoiceList);
    fetchInvoices();
  };

  const confirmApprove = async () => {
    handleCloseModals();
    await approveInvoicesByIds(
      selectedSingleInvoiceId ? [selectedSingleInvoiceId] : selectedRows
    );

    const updatedInvoiceList = invoicesList.map((invoice) => {
      if (
        selectedRows.includes(invoice.id as number) ||
        invoice.id === selectedSingleInvoiceId
      ) {
        return {
          ...invoice,
          stage: InvoiceStages.APPROVED
        };
      }
      return invoice;
    });
    setInvoicesList(updatedInvoiceList);
    fetchInvoices();
  };

  const confirmReject = async () => {
    handleCloseModals();
    await rejectInvoicesByIds(
      selectedSingleInvoiceId ? [selectedSingleInvoiceId] : selectedRows
    );

    const updatedInvoiceList = invoicesList.map((invoice) => {
      if (
        selectedRows.includes(invoice.id as number) ||
        invoice.id === selectedSingleInvoiceId
      ) {
        return {
          ...invoice,
          stage: InvoiceStages.REJECTED
        };
      }
      return invoice;
    });
    setInvoicesList(updatedInvoiceList);
    fetchInvoices();
  };

  const confirmExportForPayment = async (values: ExportForPaymentForm) => {
    handleCloseModals();
    const body = {
      iban: values.iban,
      invoiceIds: selectedRows
    };
    const { fileContent, fileName } = await exportInvoicesPayment(
      Number(companyId),
      body
    );
    await exportTxtFile(fileContent, fileName);
    fetchInvoices();
  };

  const confirmDownloadInvoices = async () => {
    handleCloseModals();
    setIsProcessingDownload(true);
    let response;
    try {
      response = await exportInvoicesZipByIds(selectedRows);
    } catch (error) {
      setIsProcessingDownload(false);
    }
    const { fileContent, fileName } = await response;
    await downloadZippedInvoices(fileContent, fileName);
    setIsProcessingDownload(false);
    fetchInvoices();
  };

  const handleCreateClick = async () => {
    const createdInvoice = await createWithoutDocument(Number(companyId));
    navigate(
      AppRoutesEnum.INVOICE_VERIFICATION.replace(
        ID_PARAMETER,
        createdInvoice.id.toString()
      ).replace(COMPANY_PARAMETER, String(companyId)),
      { state: { invoice: stateRoute } }
    );
  };

  const isInAllCompanies = stateRoute === AppRoutesEnum.INVOICES;

  const handleExportToExcel = useCallback(async () => {
    if (isInAllCompanies) {
      const response = await exportInvoicesExcelByFilters(filters);
      const { fileContent, fileName } = await response;
      await exportTxtFile(fileContent, fileName);
      return;
    }

    const response = await exportInvoicesExcelByCompanyIdAndFilters(
      Number(companyId),
      filters
    );
    const { fileContent, fileName } = await response;
    await exportTxtFile(fileContent, fileName);
  }, [filters, companyId, exportInvoicesExcelByFilters, exportTxtFile]);

  const getDisabledDeleteTooltip = useCallback(
    (row: GridValidRowModel) => {
      if (
        row.stage === InvoiceStages.UPLOADED ||
        row.stage === InvoiceStages.DRAFT
      ) {
        return translate('messages.disabledDeleteInvoicePermission');
      }
      return translate('messages.disabledDeleteInvoice');
    },
    [translate]
  );

  const onDrop = useCallback(
    (acceptedFiles: any, fileRejections: any) => {
      if (fileRejections.length > 0) {
        const { label, message } = getUnsupportedFormatMessage(fileRejections);
        const notSupportedMessage = translate(label, {
          invoices: message
        });

        showWarnMessage(notSupportedMessage);
      }

      if (handleUploadInvoice && acceptedFiles.length > 0) {
        handleUploadInvoice(acceptedFiles, 'invoiceFile');
      }
    },
    [handleUploadInvoice]
  );

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      onDrop,
      noClick: true,
      multiple: true,
      accept: {
        'application/pdf': ['.pdf'],
        'image/png': ['.png'],
        'image/jpeg': ['.jpg', '.jpeg']
      }
    });
  const canBeDroppable = useMemo(
    () => filterType === FilterType.Initial && !isInAllCompanies,
    [filterType, isInAllCompanies]
  );

  const getDisabledApproveTooltip = useCallback(
    (row: GridValidRowModel) => {
      if (row.stage !== InvoiceStages.VALIDATED) {
        return translate('messages.disabledApproveInvoiceStatus');
      }

      if (!row.isApprovable) {
        return translate('messages.disabledApproveInvoice');
      }

      return '';
    },
    [translate, user]
  );

  const getDisabledRejectTooltip = useCallback(
    (row: GridValidRowModel) => {
      if (row.stage !== InvoiceStages.VALIDATED) {
        return translate('messages.disabledRejectInvoiceStatus');
      }

      if (!row.isApprovable) {
        return translate('messages.disabledRejectInvoice');
      }

      return '';
    },
    [translate, user]
  );

  const columns: GridColDef[] = useMemo(() => {
    return getInvoiceColumns(isInAllCompanies, translate).map((column) => {
      switch (column.field) {
        case InvoiceSortColumns.STAGE:
          return {
            ...column,
            renderCell: (params: any) => {
              const { color, backgroundColor } = getSeverityColor(
                params.row[InvoiceSortColumns.STAGE]
              );
              return (
                <Box sx={{ width: '100%' }}>
                  {params.row[InvoiceSortColumns.STAGE] ===
                  InvoiceStages.UPLOADED ? (
                    <Skeleton animation="wave" />
                  ) : (
                    <SeverityPill
                      color={color}
                      backgroundColor={backgroundColor}
                    >
                      {params.row[InvoiceSortColumns.STAGE]}
                    </SeverityPill>
                  )}
                </Box>
              );
            }
          };
        case InvoiceSortColumns.INVOICE_AMOUNT:
          return {
            ...column,
            renderCell: ({ row }: GridCellParams) =>
              row[InvoiceSortColumns.STAGE] === InvoiceStages.UPLOADED ? (
                <Box sx={{ width: '100%' }}>
                  <Skeleton animation="wave" />
                </Box>
              ) : (
                `${formatMoney(row[InvoiceSortColumns.INVOICE_AMOUNT])} ${
                  row[InvoiceSortColumns.CURRENCY] || ''
                }`
              ),
            valueGetter: (params: GridValueGetterParams) =>
              `${formatMoney(params.row[InvoiceSortColumns.INVOICE_AMOUNT])} ${
                params.row[InvoiceSortColumns.CURRENCY] || ''
              }`
          };
        case IS_ALREADY_PAID:
          return {
            ...column,
            renderCell: ({ row }: GridCellParams) =>
              row[InvoiceSortColumns.STAGE] === InvoiceStages.UPLOADED ? (
                <Box sx={{ width: '100%' }}>
                  <Skeleton animation="wave" />
                </Box>
              ) : (
                <CheckIcon
                  sx={{
                    display: row.isAlreadyPaid ? 'block' : 'none',
                    fontSize: '1.1rem'
                  }}
                />
              )
          };
        case APPROVERS:
          return {
            ...column,
            renderCell: ({ row }: GridCellParams) =>
              row[InvoiceSortColumns.STAGE] === InvoiceStages.UPLOADED ? (
                <Box sx={{ width: '100%' }}>
                  <Skeleton animation="wave" />
                </Box>
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    gap: '0.2rem'
                  }}
                >
                  {row.expenseTypeApprovers?.length ? (
                    <Tooltip
                      title={
                        <>
                          <Box
                            component="div"
                            sx={{
                              display: 'inline',
                              fontWeight: 600
                            }}
                          >
                            {`${translate('labels.expenseTypeApprovers')}:`}
                          </Box>
                          {row.expenseTypeApprovers.map(
                            (
                              approver: string,
                              index: Key | null | undefined
                            ) => (
                              <span key={index}>
                                <br />
                                {approver}
                              </span>
                            )
                          )}
                        </>
                      }
                    >
                      <Chip className="approvers-chip" label="ET" />
                    </Tooltip>
                  ) : null}
                  {row.counterpartyApprovers?.length ? (
                    <Tooltip
                      title={
                        <>
                          <Box
                            component="div"
                            sx={{ display: 'inline', fontWeight: 600 }}
                          >
                            {`${translate('labels.counterpartyApprovers')}:`}
                          </Box>
                          {row.counterpartyApprovers.map(
                            (
                              approver: string,
                              index: Key | null | undefined
                            ) => (
                              <span key={index}>
                                <br />
                                {approver}
                              </span>
                            )
                          )}
                        </>
                      }
                    >
                      <Chip className="approvers-chip" label="CP" />
                    </Tooltip>
                  ) : null}
                  {row.amountApprovers?.length ? (
                    <Tooltip
                      title={
                        <>
                          <Box
                            component="div"
                            sx={{ display: 'inline', fontWeight: 600 }}
                          >
                            {`${translate('labels.amountApprovers')}:`}
                          </Box>
                          {row.amountApprovers.map(
                            (
                              approver: string,
                              index: Key | null | undefined
                            ) => (
                              <span key={index}>
                                <br />
                                {approver}
                              </span>
                            )
                          )}
                        </>
                      }
                    >
                      <Chip className="approvers-chip" label="A" />
                    </Tooltip>
                  ) : null}
                </Box>
              )
          };
        case InvoiceSortColumns.COUNTERPARTY_NAME:
          return {
            ...column,
            renderCell: ({ row }: GridCellParams) =>
              getInvoiceColumnsWithSkeleton(
                row,
                InvoiceSortColumns.COUNTERPARTY_NAME
              )
          };
        case InvoiceSortColumns.INVOICE_NUMBER:
          return {
            ...column,
            renderCell: ({ row }: GridCellParams) =>
              getInvoiceColumnsWithSkeleton(
                row,
                InvoiceSortColumns.INVOICE_NUMBER
              )
          };
        case InvoiceSortColumns.INVOICE_DATE:
          return {
            ...column,
            renderCell: ({ row }: GridCellParams) =>
              getInvoiceColumnsWithSkeleton(
                row,
                InvoiceSortColumns.INVOICE_DATE
              )
          };
        case InvoiceSortColumns.INSERTED_BY:
          return {
            ...column,
            renderCell: ({ row }: GridCellParams) =>
              getInvoiceColumnsWithSkeleton(row, InvoiceSortColumns.INSERTED_BY)
          };
        case InvoiceSortColumns.EXPENSE_TYPE:
          return {
            ...column,
            renderCell: ({ row }: GridCellParams) =>
              getInvoiceColumnsWithSkeleton(
                row,
                InvoiceSortColumns.EXPENSE_TYPE
              )
          };
        case InvoiceSortColumns.INSERTED_AT:
          return {
            ...column,
            renderCell: ({ row }: GridCellParams) =>
              getInvoiceColumnsWithSkeleton(row, InvoiceSortColumns.INSERTED_AT)
          };
        case ACTIONS:
          return {
            ...column,
            minWidth: filterType === FilterType.Initial ? 130 : 70,
            maxWidth: filterType === FilterType.Initial ? 130 : 70,
            renderCell: ({ row }: any) => (
              <Box display="flex" justifyContent="center">
                {filterType === FilterType.Initial && (
                  <Tooltip
                    placement="left"
                    title={
                      !row.isDeletable ? getDisabledDeleteTooltip(row) : ''
                    }
                  >
                    <span>
                      <GridActionsCellItem
                        disabled={!row.isDeletable}
                        icon={
                          <DeleteTwoToneIcon
                            sx={(theme) => ({
                              color: row.isDeletable
                                ? theme.palette.error.main
                                : 'default',
                              fontSize: '1.5rem'
                            })}
                            titleAccess={translate('labels.delete')}
                          />
                        }
                        label={translate('labels.delete')}
                        onClick={() => {
                          openDeleteModal();
                          setSelectedSingleInvoiceId(row.id);
                        }}
                      />
                    </span>
                  </Tooltip>
                )}
                {(filterType === FilterType.Initial ||
                  filterType === FilterType.Approval) && (
                  <Tooltip title={getDisabledApproveTooltip(row)}>
                    <span>
                      <GridActionsCellItem
                        disabled={!row.isApprovable}
                        icon={
                          <Checklist
                            sx={{
                              fontSize: '1.5rem'
                            }}
                            color={!row.isApprovable ? 'disabled' : 'primary'}
                            titleAccess={translate('buttons.approve')}
                          />
                        }
                        label={translate('buttons.approve')}
                        onClick={() => {
                          openApproveModal();
                          setSelectedSingleInvoiceId(row.id);
                        }}
                      />
                    </span>
                  </Tooltip>
                )}
                {(filterType === FilterType.Initial ||
                  filterType === FilterType.Approval) && (
                  <Tooltip title={getDisabledRejectTooltip(row)}>
                    <span>
                      <GridActionsCellItem
                        disabled={!row.isApprovable}
                        icon={
                          <PlaylistRemoveIcon
                            sx={{
                              fontSize: '1.5rem'
                            }}
                            color={!row.isApprovable ? 'disabled' : 'primary'}
                            titleAccess={translate('buttons.reject')}
                          />
                        }
                        label={translate('buttons.reject')}
                        onClick={() => {
                          openRejectModal();
                          setSelectedSingleInvoiceId(row.id);
                        }}
                      />
                    </span>
                  </Tooltip>
                )}
                {(filterType === FilterType.Initial ||
                  filterType === FilterType.Payment) && (
                  <Tooltip
                    title={
                      row.stage !== InvoiceStages.APPROVED || !row.isTrusted
                        ? translate('messages.disabledPayInvoice')
                        : ''
                    }
                  >
                    <span>
                      <GridActionsCellItem
                        disabled={
                          row.stage !== InvoiceStages.APPROVED || !row.isTrusted
                        }
                        icon={
                          <PaymentIcon
                            sx={{
                              fontSize: '1.5rem'
                            }}
                            color={
                              row.stage !== InvoiceStages.APPROVED ||
                              !row.isTrusted
                                ? 'disabled'
                                : 'primary'
                            }
                            titleAccess={translate('buttons.pay')}
                          />
                        }
                        label={translate('buttons.pay')}
                        onClick={() => {
                          openPayModal();
                          setSelectedSingleInvoiceId(row.id);
                        }}
                      />
                    </span>
                  </Tooltip>
                )}
              </Box>
            )
          };
        case InvoiceSortColumns.SHORT_COMPANY_NAME:
          return {
            ...column,
            renderCell: ({ row }: GridCellParams) =>
              getInvoiceColumnsWithSkeleton(
                row,
                InvoiceSortColumns.SHORT_COMPANY_NAME
              )
          };
        default:
          return column;
      }
    });
  }, [translate, isInAllCompanies, filterType, user, invoicesList]);

  const getRowId = useCallback(
    (row: GridValidRowModel) => row.id || row.name,
    []
  );

  const getRowClassName = useCallback(
    (params: GridRowClassNameParams<GridValidRowModel>) => {
      if (params.row.isAccounted) {
        return ACCOUNTED_INVOICE_STYLE;
      }
      return '';
    },
    []
  );

  const onCellClick = useCallback(
    (params: GridCellParams) => {
      if (
        params.field !== ACTIONS &&
        params.field !== CHECK &&
        params.row[InvoiceSortColumns.STAGE] !== InvoiceStages.UPLOADED
      ) {
        navigate(
          AppRoutesEnum.INVOICE_VERIFICATION.replace(
            ID_PARAMETER,
            params.row.id.toString()
          ).replace(COMPANY_PARAMETER, params.row.companyId.toString()),
          { state: { invoice: stateRoute } }
        );
      }
    },
    [navigate, stateRoute]
  );

  const handleShowFilters = () => {
    setAreFiltersVisible((previousValue) => !previousValue);
  };

  const height = useMemo(
    () =>
      getContainerHeight(
        areFiltersVisible,
        INVOICES_FILTERS_EXPANDED_HEIGHT,
        isWithoutQuickFilter
      ),
    [areFiltersVisible, isWithoutQuickFilter]
  );

  const shouldShowMultiSelectInfo = useMemo(() => {
    return selectedRows.length > 1 && selectedSingleInvoiceId === undefined;
  }, [selectedRows, selectedSingleInvoiceId]);

  return (
    <Stack sx={commonDataGridContainerStyle}>
      {isWithoutQuickFilter && (
        <Box display="flex" justifyContent="flex-end" gap={2}>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleExportToExcel}
          >
            {translate('buttons.exportToExcel')}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleShowFilters}
          >
            <FilterListIcon />
            {translate('buttons.filters')}
          </Button>
        </Box>
      )}
      {isProcessingDownload && <OverlayLoading />}
      <Stack
        sx={{
          ...commonDataGridContainerStyle,
          height
        }}
        {...(canBeDroppable ? getRootProps() : {})}
      >
        {canBeDroppable && (
          <input {...getInputProps()} style={{ display: 'none' }} />
        )}
        {isWithoutQuickFilter && (
          <InvoicesFilterPanel
            handleApplyFilters={handleApplyFilters}
            filtersData={invoicesFiltersData}
            areFiltersVisible={areFiltersVisible}
            isInAllCompanies={isInAllCompanies}
          />
        )}
        <Paper
          elevation={4}
          sx={{
            ...commonDataGridContainerStyle,
            marginTop: '1rem',
            minHeight: 'calc(100% - 150px)',
            outline: isDragActive ? '2px dashed #2196f3' : 'none',
            position: 'relative'
          }}
        >
          <DataGrid
            rows={invoicesList}
            columns={columns}
            getRowId={getRowId}
            loading={isLoading}
            sx={invoicesDataGrid}
            getRowClassName={getRowClassName}
            sortingMode="server"
            paginationMode="server"
            rowCount={rowCount}
            onPaginationModelChange={handlePaginationModelChange}
            paginationModel={paginationModel}
            pageSizeOptions={[]}
            slots={{
              footer: CustomGridFooter,
              toolbar: GridToolbar,
              noResultsOverlay: CircularProgress
            }}
            onSortModelChange={handleSortModelChange}
            rowSelectionModel={selectedRows}
            onRowSelectionModelChange={(rows) =>
              setSelectedRows(rows as number[])
            }
            rowHeight={DEFAULT_GRID_ROW_HEIGHT}
            onCellClick={onCellClick}
            checkboxSelection={permissions.INVOICES.read}
            disableRowSelectionOnClick
            disableColumnMenu
            slotProps={{
              toolbar: {
                selectedRows,
                companyIbans,
                isDefaultToolbarHidden: true,
                handleDeleteClick:
                  filterType === FilterType.Initial
                    ? openDeleteModal
                    : undefined,
                handleUploadInvoice:
                  filterType === FilterType.Initial
                    ? handleUploadInvoice
                    : undefined,
                handleAccountClick:
                  permissions.INVOICE_ACCOUNTING.update &&
                  (filterType === FilterType.Accounting ||
                    filterType === FilterType.Initial)
                    ? openAccountModal
                    : undefined,
                handlePayClick:
                  permissions.INVOICE_PAYMENTS.create &&
                  (filterType === FilterType.Payment ||
                    filterType === FilterType.Initial)
                    ? openPayModal
                    : undefined,
                handleApproveClick:
                  permissions.INVOICE_APPROVAL.update &&
                  (filterType === FilterType.Approval ||
                    filterType === FilterType.Initial)
                    ? openApproveModal
                    : undefined,
                handleRejectClick:
                  permissions.INVOICE_APPROVAL.update &&
                  (filterType === FilterType.Approval ||
                    filterType === FilterType.Initial)
                    ? openRejectModal
                    : undefined,
                handleExportForPaymentClick:
                  !isInAllCompanies &&
                  (filterType === FilterType.Initial ||
                    filterType === FilterType.Payment)
                    ? openExportForPaymentModal
                    : undefined,
                handleDownloadClick: confirmDownloadInvoices,
                handleCreateClick:
                  !isInAllCompanies && filterType === FilterType.Initial
                    ? handleCreateClick
                    : undefined,
                entries: invoicesList,
                deleteButtonTooltip: translate(
                  'messages.disabledDeleteInvoices'
                )
              }
            }}
            localeText={{
              noRowsLabel: translate('labels.noData')
            }}
          />
          {canBeDroppable && isDragActive && (
            <OverlayDropFiles isReject={isDragReject} />
          )}
          <ConfirmationDialog
            title={translate('titles.delete')}
            isOpen={isDeleteModalOpen}
            onClose={handleCloseModals}
            size="sm"
            onConfirm={confirmDelete}
          >
            {shouldShowMultiSelectInfo
              ? translate('messages.deleteInvoicesConfirmation', {
                  numberOfInvoices: selectedRows.length.toString()
                })
              : translate('messages.deleteInvoiceConfirmation')}
          </ConfirmationDialog>
          <ConfirmationDialog
            title={translate('titles.book')}
            isOpen={isAccountModalOpen}
            onClose={handleCloseModals}
            size="sm"
            onConfirm={confirmAccount}
          >
            {shouldShowMultiSelectInfo
              ? translate('messages.accountInvoicesConfirmation', {
                  numberOfInvoices: selectedRows.length.toString()
                })
              : translate('messages.accountInvoiceConfirmation')}
          </ConfirmationDialog>
          <ConfirmationDialog
            title={translate('buttons.pay')}
            isOpen={isPayModalOpen}
            onClose={handleCloseModals}
            size="sm"
            onConfirm={confirmPay}
          >
            {shouldShowMultiSelectInfo
              ? translate('messages.payInvoicesConfirmation', {
                  numberOfInvoices: selectedRows.length.toString()
                })
              : translate('messages.payInvoiceConfirmation')}
          </ConfirmationDialog>
          <ConfirmationDialog
            title={translate('buttons.approve')}
            isOpen={isApproveModalOpen}
            onClose={handleCloseModals}
            size="sm"
            onConfirm={confirmApprove}
          >
            {shouldShowMultiSelectInfo
              ? translate('messages.approveInvoicesConfirmation', {
                  numberOfInvoices: selectedRows.length.toString()
                })
              : translate('messages.approveInvoiceConfirmation')}
          </ConfirmationDialog>
          <ConfirmationDialog
            title={translate('buttons.reject')}
            isOpen={isRejectModalOpen}
            onClose={handleCloseModals}
            size="sm"
            onConfirm={confirmReject}
          >
            {shouldShowMultiSelectInfo
              ? translate('messages.rejectInvoicesConfirmation', {
                  numberOfInvoices: selectedRows.length.toString()
                })
              : translate('messages.rejectInvoiceConfirmation')}
          </ConfirmationDialog>
          <Modal
            headerTitle={translate('buttons.exportForPayment')}
            isOpen={isExportForPaymentModalOpen}
            hide={handleCloseModals}
            maxWidth="sm"
          >
            <SelectIbanForm
              onSubmit={confirmExportForPayment}
              companyIbans={companyIbans || []}
            />
          </Modal>
        </Paper>
      </Stack>
    </Stack>
  );
};
