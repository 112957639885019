import moment from 'moment';

import {
  Invoice,
  InvoicePatch,
  InvoiceQueryFilters,
  InvoiceRow,
  InvoiceSortColumns,
  DocumentType
} from 'openapi';

import { DATE_FORMATS, INITIAL_IBAN_ITEM } from 'utils/constants/constants';
import { INITIAL_PRODUCT_ITEM } from 'utils/constants/invoices';
import { decimalFormater } from 'utils/helpers/invoiceHelpers';
import { InvoicesFiltersForm } from 'utils/interfaces/InvoiceProps';

export const mapInvoiceForPatch = (invoice: Invoice): InvoicePatch => ({
  invoiceNumber: invoice.invoiceNumber,
  invoiceDate: invoice.invoiceDate,
  dueDate: invoice.dueDate,
  vatBase: invoice.vatBase,
  vatAmount: invoice.vatAmount,
  invoiceAmount: invoice.invoiceAmount,
  productDescription: invoice.productDescription,
  expenseTypeId: invoice.expenseType?.id,
  documentType: invoice.documentType,
  products: invoice.products,
  counterpartyId: invoice.counterpartyId,
  companyId: invoice.companyId,
  currencyId: invoice.currencyId
});

export const mapInvoice = (invoice: Invoice): Invoice =>
  ({
    counterpartyName: invoice?.counterpartyName || '',
    invoiceNumber: invoice?.invoiceNumber || '',
    invoiceDate: invoice?.invoiceDate || '',
    counterpartyRegistrationNumber:
      invoice?.counterpartyRegistrationNumber || '',
    dueDate: invoice?.dueDate || '',
    counterpartyVatNumber: invoice?.counterpartyVatNumber || '',
    vatBase: decimalFormater(invoice?.vatBase as number),
    vatAmount: decimalFormater(invoice?.vatAmount as number) || '',
    invoiceAmount: decimalFormater(invoice?.invoiceAmount as number) || '',
    currencyId: invoice?.currencyId || 1,
    currency: invoice?.currency || 1,
    accountingDescription: invoice.accountingDescription,
    productDescription: invoice?.productDescription || '',
    products:
      invoice?.products && invoice.products.length > 0
        ? invoice.products
        : [{ ...INITIAL_PRODUCT_ITEM }],
    expenseType: invoice?.expenseType || null,
    documentType: invoice?.documentType || '',
    companyId: invoice?.companyId || '',
    counterpartyId: invoice?.counterpartyId || '',
    profitAndLossAccountId: invoice.profitAndLossAccountId || '',
    isReceiverVerified: invoice.isReceiverVerified || false,
    isSupplierVerified: invoice.isSupplierVerified || false,
    areIbansVerified: invoice.areIbansVerified || false,
    isInvoiceDataVerified: invoice.isInvoiceDataVerified || false,
    areProductsVerified: invoice.areProductsVerified || false,
    ibanList: invoice.ibanList?.length
      ? invoice.ibanList
      : [{ ...INITIAL_IBAN_ITEM }]
  } as Invoice);

export const mapInvoiceForReceiverSubmit = (
  invoice: Invoice
): InvoicePatch => ({
  companyId: invoice?.companyId,
  documentType: invoice?.documentType,
  isAlreadyPaid: invoice?.isAlreadyPaid
});

export const mapInvoiceForSupplierSubmit = (
  invoice: Invoice
): InvoicePatch => ({
  counterpartyId: invoice.counterpartyId,
  counterpartyName: invoice.counterpartyName,
  counterpartyVatNumber: invoice.counterpartyVatNumber || undefined,
  counterpartyRegistrationNumber:
    invoice.counterpartyRegistrationNumber || undefined,
  expenseTypeId: invoice.expenseType?.id,
  products: invoice.products,
  ibanList: invoice.ibanList?.filter((iban) => iban.id || iban.iban)
});

export const mapInvoiceForIbanSubmit = (invoice: Invoice): InvoicePatch => ({
  ibanList: invoice.ibanList?.filter((iban) => iban.id || iban.iban)
});

export const mapInvoiceForInvoiceSubmit = (invoice: Invoice): InvoicePatch => ({
  currencyId: invoice.currencyId,
  dueDate: invoice.dueDate,
  expenseTypeId: invoice.expenseType?.id,
  invoiceAmount: invoice.invoiceAmount,
  invoiceDate: invoice.invoiceDate,
  invoiceNumber: invoice.invoiceNumber,
  products: invoice.products,
  vatAmount: invoice.vatAmount,
  vatBase: invoice.vatBase
});

export const mapInvoiceForProductsSubmit = (
  invoice: Invoice
): InvoicePatch => ({
  products: invoice.products
});

export const getMappedInvoiceFilters = (
  filters: InvoicesFiltersForm
): InvoiceQueryFilters => ({
  stages: filters.stages,
  counterpartyNames: filters.counterpartyNames,
  shortCompanyNames: filters.shortCompanyNames,
  expenseTypes: filters.expenseTypes,
  insertedBy: filters.insertedBy,
  approvers: filters.approvers,
  invoiceDate: {
    from: filters.invoiceDateFrom
      ? moment(filters.invoiceDateFrom).format(DATE_FORMATS.displayedDateFormat)
      : undefined,
    to: filters.invoiceDateTo
      ? moment(filters.invoiceDateTo).format(DATE_FORMATS.displayedDateFormat)
      : undefined
  },
  uploadedDate: {
    from: filters.uploadedDateFrom
      ? moment(filters.uploadedDateFrom).format(
          DATE_FORMATS.displayedDateFormat
        )
      : undefined,
    to: filters.uploadedDateTo
      ? moment(filters.uploadedDateTo).format(DATE_FORMATS.displayedDateFormat)
      : undefined
  },
  amount: {
    from: filters.amountFrom || undefined,
    to: filters.amountTo || undefined
  },
  isAccounted: filters.accounted ? filters.accounted === 'true' : undefined,
  documentType: filters.documentType
    ? (filters.documentType as DocumentType)
    : undefined
});

export const getMappedInvoices = (invoices: InvoiceRow[]) =>
  invoices.map((invoice: InvoiceRow) => ({
    id: invoice.id,
    companyId: invoice.companyId,
    [InvoiceSortColumns.STAGE]: invoice.stage,
    [InvoiceSortColumns.SHORT_COMPANY_NAME]: invoice.shortCompanyName,
    [InvoiceSortColumns.COUNTERPARTY_NAME]: invoice.counterpartyName,
    [InvoiceSortColumns.INVOICE_NUMBER]: invoice.invoiceNumber,
    [InvoiceSortColumns.INVOICE_DATE]: invoice.invoiceDate,
    [InvoiceSortColumns.INVOICE_AMOUNT]: invoice.invoiceAmount,
    [InvoiceSortColumns.INSERTED_BY]: invoice.insertedBy,
    [InvoiceSortColumns.INSERTED_AT]: invoice.insertedAt,
    [InvoiceSortColumns.CURRENCY]: invoice.currency,
    [InvoiceSortColumns.EXPENSE_TYPE]: invoice.expenseType,
    amountApprovers: invoice.amountApprovers,
    expenseTypeApprovers: invoice.expenseTypeApprovers,
    counterpartyApprovers: invoice.counterpartyApprovers,
    isDeletable: invoice.isDeletable,
    stage: invoice.stage,
    isAccounted: invoice.isAccounted,
    isAlreadyPaid: invoice.isAlreadyPaid,
    documentType: invoice.documentType,
    isExportable: invoice.isExportable,
    isApprovable: invoice.isApprovable,
    isTrusted: invoice.isTrusted
  }));

export const getFormFilters = (filters: any): InvoicesFiltersForm => {
  return {
    stages: filters.stages || [],
    counterpartyNames: filters.counterpartyNames || [],
    shortCompanyNames: filters.shortCompanyNames || [],
    expenseTypes: filters.expenseTypes || [],
    insertedBy: filters.insertedBy || [],
    approvers: filters.approvers || [],
    invoiceDateFrom: filters.invoiceDate.from
      ? moment(
          filters.invoiceDate.from,
          DATE_FORMATS.displayedDateFormat
        ).toDate()
      : null,
    invoiceDateTo: filters.invoiceDate.to
      ? moment(
          filters.invoiceDate.to,
          DATE_FORMATS.displayedDateFormat
        ).toDate()
      : null,
    uploadedDateFrom: filters.uploadedDate.from
      ? moment(
          filters.uploadedDate.from,
          DATE_FORMATS.displayedDateFormat
        ).toDate()
      : null,
    uploadedDateTo: filters.uploadedDate.to
      ? moment(
          filters.uploadedDate.to,
          DATE_FORMATS.displayedDateFormat
        ).toDate()
      : null,
    amountFrom: filters.amount.from || '',
    amountTo: filters.amount.to || '',
    accounted:
      filters.isAccounted !== undefined && filters.isAccounted !== null
        ? filters.isAccounted.toString()
        : '',
    documentType: filters.documentType || ''
  };
};
