/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CounterpartyDropDownListResponse } from '../models/CounterpartyDropDownListResponse';
import type { CurrencyDropDownListResponse } from '../models/CurrencyDropDownListResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DropDownHelperService {

    /**
     * This method returns the list of counterparties by for a drop-down selection
     * @param searchParam The counterparty name, registration number or VAT number
     * @param companyId The company id
     * @returns CounterpartyDropDownListResponse OK
     * @throws ApiError
     */
    public static getAllDropDownCounterparties(
        searchParam?: string,
        companyId?: number,
    ): CancelablePromise<CounterpartyDropDownListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/drop-down/counterparties',
            query: {
                'searchParam': searchParam,
                'companyId': companyId,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * This method returns the list of currencies for a drop-down selection in invoice screen
     * @returns CurrencyDropDownListResponse OK
     * @throws ApiError
     */
    public static getAllCurrenciesForDropDown(): CancelablePromise<CurrencyDropDownListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/drop-down/currencies',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

}
