import { GridSortModel, GridPaginationModel } from '@mui/x-data-grid';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { useAtom } from 'jotai';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useIbanController } from 'api/controllers/IbanController';
import { useInvoiceController } from 'api/controllers/InvoiceController';

import {
  CompanyIban,
  Invoice,
  InvoiceFilters,
  OpenAPI,
  SortOrder
} from 'openapi';

import { InvoicesDataGrid } from 'components/InvoicesDataGrid/InvoicesDataGrid';
import { Layout } from 'components/Layout/Layout';
import { OverlayLoading } from 'components/overlays/OverlayLoading';

import { useTranslations } from 'context/TranslationContext';

import { usePagination } from 'hooks/usePagination';

import {
  ACCESS_TOKEN,
  BUTTON,
  HEARTBEAT_TIMEOUT
} from 'utils/constants/constants';
import { INITIAL_INVOICES_FILTERS } from 'utils/constants/invoices';
import { INITIAL_PAGINATION_PROPS } from 'utils/constants/paginations';
import { InvoiceEventType } from 'utils/enums/Invoice';
import { getInvoicesFiltersAtomByType } from 'utils/helpers/filtersHelpers';
import { handleSortModelChange } from 'utils/helpers/invoiceHelpers';
import {
  getEventData,
  getSSERequestOptions,
  getUrl
} from 'utils/helpers/serverSentEvents';
import {
  getInvoiceWarningMessage,
  showWarnMessage
} from 'utils/helpers/toastHelpers';
import { InvoicesFiltersForm } from 'utils/interfaces/InvoiceProps';
import {
  getMappedInvoiceFilters,
  getMappedInvoices
} from 'utils/mappers/invoice';
import { AppRoutesEnum } from 'utils/routes';

import {
  invoicesPaginationAtom,
  filterTypeAtom,
  isSavingAtom,
  userAtom
} from 'state/state';

export const DocumentsUpload = (): JSX.Element => {
  const { companyId } = useParams();
  const {
    addInvoice,
    getInvoiceFiltersByCompany,
    getInvoicesByCompanyAndFilters,
    getInvoiceRow
  } = useInvoiceController();
  const { getIbansByCompany } = useIbanController();
  const { translate } = useTranslations();

  const [totalInvoices, setTotalInvoices] = useState<number>(0);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const [filterType] = useAtom(filterTypeAtom);
  const [pagination, setPagination] = useAtom(invoicesPaginationAtom);
  const [isSaving] = useAtom(isSavingAtom);
  const [filters, setFilters] = useAtom(
    getInvoicesFiltersAtomByType(filterType)
  );
  const [user] = useAtom(userAtom);

  const { paginationModel, handlePaginationModelChange } = usePagination({
    page: pagination.page,
    pageSize: pagination.pageSize,
    rowCount: totalInvoices
  });

  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [invoicesFiltersData, setInvoicesFiltersData] =
    useState<InvoiceFilters>({});
  const [isFiltered, setIsFiltered] = useState<boolean>(false);
  const [companyIbans, setCompanyIbans] = useState<CompanyIban[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const filtersRef = useRef(filters);

  const fetchCompanyInvoices = useCallback(async () => {
    setIsLoading(true);
    const currentFilters = filtersRef.current;
    const result = await getInvoicesByCompanyAndFilters(Number(companyId), {
      ...currentFilters,
      pageNumber: paginationModel.page + 1,
      pageSize: paginationModel.pageSize,
      sortBy: currentFilters.sortBy,
      sortOrder: currentFilters.sortOrder?.toUpperCase() as SortOrder
    });

    const mappedInvoices = getMappedInvoices(result.invoices);
    setInvoices(mappedInvoices);
    setTotalInvoices(result.totalElements || 0);
    setIsLoading(false);
  }, [companyId, paginationModel, getInvoicesByCompanyAndFilters, filtersRef]);

  const fetchInvoiceFiltersByCompany = useCallback(async () => {
    const result = await getInvoiceFiltersByCompany(Number(companyId));
    setInvoicesFiltersData(result);
  }, [companyId]);

  const handleApplyFilters = useCallback(
    (data: InvoicesFiltersForm | null) => {
      const mappedFilters = getMappedInvoiceFilters(
        data || ({} as InvoicesFiltersForm)
      );
      setIsFiltered(!!data);
      // @ts-ignore
      setFilters({
        ...INITIAL_INVOICES_FILTERS,
        ...mappedFilters
      });
      handlePaginationModelChange({
        ...paginationModel,
        page: INITIAL_PAGINATION_PROPS.page
      });
    },
    [setFilters, paginationModel]
  );

  const handleSortChange = useCallback(
    (sortModel: GridSortModel) => {
      handleSortModelChange(sortModel, setFilters);
    },
    [setFilters]
  );

  const handleUploadInvoice = async (
    fileData: FileList | File[],
    field: string
  ) => {
    setIsUploading(true);
    let blobArray;

    if (field === BUTTON) {
      blobArray = Array.from(fileData as []).map((file) => file as Blob);
    } else {
      blobArray = fileData;
    }

    try {
      await addInvoice(Number(companyId), {
        files: blobArray as Array<Blob>
      });
    } catch (error) {
      setIsUploading(false);
    } finally {
      setIsUploading(false);
    }
  };

  const handlePagination = useCallback(
    (newPaginationModel: GridPaginationModel) => {
      setInvoices([]);
      handlePaginationModelChange(newPaginationModel);
    },
    [setInvoices, handlePaginationModelChange]
  );

  const token = useMemo(
    () => localStorage.getItem(ACCESS_TOKEN),
    [localStorage.getItem(ACCESS_TOKEN)]
  );

  const handleInvoiceEvent = useCallback(
    async (data: string) => {
      const { eventId, eventType } = getEventData(data);
      const currentFilters = filtersRef.current;

      const shouldNotUpdate =
        eventType === InvoiceEventType.HEARTBEAT ||
        isFiltered ||
        paginationModel.page !== INITIAL_PAGINATION_PROPS.page ||
        currentFilters.sortBy !== INITIAL_INVOICES_FILTERS.sortBy ||
        currentFilters.sortOrder !== INITIAL_INVOICES_FILTERS.sortOrder;

      if (
        shouldNotUpdate &&
        eventType !== InvoiceEventType.INVOICE_DELETE &&
        eventType !== InvoiceEventType.INVOICE_DUPLICATE
      ) {
        return;
      }

      if (eventType === InvoiceEventType.INVOICE_DUPLICATE) {
        const { label, message } = getInvoiceWarningMessage(eventId);
        const duplicateMessage = translate(label, {
          invoices: message
        });

        showWarnMessage(duplicateMessage);
      }

      if (eventType === InvoiceEventType.INVOICE_CREATE) {
        const invoice = await getInvoiceRow(Number(eventId));
        const mappedInvoice = getMappedInvoices([invoice]);
        setInvoices((prevInvoices) => [...mappedInvoice, ...prevInvoices]);
        setTotalInvoices((prevTotalInvoices) => prevTotalInvoices + 1);
        return;
      }

      if (eventType === InvoiceEventType.INVOICE_UPDATE) {
        const invoice = await getInvoiceRow(Number(eventId));
        const mappedInvoice = getMappedInvoices([invoice]);
        setInvoices((prevInvoices) => {
          if (prevInvoices.some((inv) => inv.id === mappedInvoice[0].id)) {
            return prevInvoices.map((inv) =>
              inv.id === mappedInvoice[0].id ? mappedInvoice[0] : inv
            );
          }
          return [...mappedInvoice, ...prevInvoices];
        });
        return;
      }

      fetchCompanyInvoices();
    },
    [paginationModel, fetchCompanyInvoices, invoices, isFiltered]
  );

  useEffect(() => {
    filtersRef.current = filters;
  }, [filters]);

  useEffect(() => {
    fetchInvoiceFiltersByCompany();
  }, []);

  useEffect(() => {
    setPagination({
      page: paginationModel.page,
      pageSize: paginationModel.pageSize
    });
    if (!Object.keys(invoicesFiltersData).length || isSaving) {
      setIsLoading(true);
      return;
    }
    fetchCompanyInvoices();
  }, [
    companyId,
    filters,
    paginationModel,
    isSaving,
    invoicesFiltersData,
    fetchCompanyInvoices
  ]);

  useEffect(() => {
    let retryTimeout: NodeJS.Timeout | undefined;
    let heartbeatTimeout: NodeJS.Timeout | undefined;
    let retryCount = 0;

    const createEventSource = (): EventSourcePolyfill => {
      const result = new EventSourcePolyfill(
        getUrl(OpenAPI, getSSERequestOptions(user?.id)),
        {
          headers: {
            Authorization: `Bearer ${token}` || ''
          }
        }
      );

      result.onmessage = (e) => {
        const { eventType } = getEventData(e.data);

        if (eventType === InvoiceEventType.HEARTBEAT) {
          if (heartbeatTimeout) clearTimeout(heartbeatTimeout);

          heartbeatTimeout = setTimeout(() => {
            result.close();
            createEventSource();
          }, HEARTBEAT_TIMEOUT);
        } else {
          handleInvoiceEvent(e.data);
        }
      };

      result.onerror = () => {
        result.close();
        if (heartbeatTimeout) clearTimeout(heartbeatTimeout);
        if (retryTimeout) clearTimeout(retryTimeout);

        const retryDelay = Math.min(10000, 2 ** retryCount * 1000);
        retryCount += 1;

        retryTimeout = setTimeout(() => {
          createEventSource();
        }, retryDelay);
      };

      return result;
    };

    const eventSourceInstance = createEventSource();

    return () => {
      eventSourceInstance.close();
      if (heartbeatTimeout) clearTimeout(heartbeatTimeout);
      if (retryTimeout) clearTimeout(retryTimeout);
    };
  }, [token]);

  const getIbansByCompanyId = useCallback(async () => {
    const ibans = await getIbansByCompany(Number(companyId));
    setCompanyIbans(ibans);
  }, [getIbansByCompany, companyId, setCompanyIbans]);

  useEffect(() => {
    getIbansByCompanyId();
  }, [getIbansByCompanyId]);

  return (
    <Layout>
      {isUploading && <OverlayLoading />}
      <InvoicesDataGrid
        invoicesList={invoices}
        filters={filters}
        setInvoicesList={setInvoices}
        stateRoute={AppRoutesEnum.COMPANY_INVOICES}
        handleUploadInvoice={handleUploadInvoice}
        invoicesFiltersData={invoicesFiltersData}
        handleApplyFilters={handleApplyFilters}
        handlePaginationModelChange={handlePagination}
        paginationModel={paginationModel}
        rowCount={totalInvoices}
        handleSortModelChange={handleSortChange}
        fetchInvoices={fetchCompanyInvoices}
        isLoading={isLoading}
        companyIbans={companyIbans}
      />
    </Layout>
  );
};
