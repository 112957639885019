import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import { useAdditionalFieldsController } from 'api/controllers/AdditionalFieldsController';

import {
  CurrencyDropDown,
  Invoice,
  InvoiceSection,
  InvoiceExpenseType,
  InvoicePatch
} from 'openapi';

import { useTranslations } from 'context/TranslationContext';

import { DATE_FORMATS } from 'utils/constants/constants';
import {
  getInvoiceDataDefaultValues,
  getInvoiceDataFormValues,
  getSaveInvoiceDataFormValues
} from 'utils/helpers/invoiceHelpers';
import { processMoneyInput } from 'utils/helpers/moneyHelper';
import {
  InvoiceDetails,
  InvoiceSectionKey
} from 'utils/interfaces/InvoiceProps';

import {
  verificationFormFields,
  verificationFormFieldsWrapper,
  verificationFormItem,
  verificationFormItemNoHeight
} from 'styles/pages/InvoiceVerificationStyle';

import { ActionButtons } from './ActionButtons';

interface InvoiceDataFormProps {
  currentInvoice: Invoice;
  triggerReprompt: boolean;
  handleSaveSection: (values: InvoicePatch, section: InvoiceSectionKey) => void;
  handleVerify: (values: Invoice, section: InvoiceSectionKey) => void;
  handleBack: (section: InvoiceSection) => void;
}

export const InvoiceDataForm = ({
  currentInvoice,
  triggerReprompt,
  handleSaveSection,
  handleVerify,
  handleBack
}: InvoiceDataFormProps) => {
  const { translate } = useTranslations();
  const location = useLocation();
  const { getCurrencies, getExpenseTypes } = useAdditionalFieldsController();

  const [currencies, setCurrencies] = useState<CurrencyDropDown[]>();
  const [expenseTypeOptions, setExpenseTypeOptions] =
    useState<InvoiceExpenseType[]>();
  const [isInvoiceDateOpen, setInvoiceDateOpen] = useState(false);
  const [isDueDateOpen, setDueDateOpen] = useState(false);
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const isVerifiedRef = useRef<boolean>(isVerified);
  const triggerRepromptRef = useRef<boolean>(triggerReprompt);

  const fetchCurrencies = useCallback(async () => {
    const currenciesResponse = await getCurrencies();
    setCurrencies(currenciesResponse);
  }, [getCurrencies]);

  const fetchExpenseTypes = useCallback(async () => {
    const expenseTypes = await getExpenseTypes(
      Number(currentInvoice.companyId)
    );
    setExpenseTypeOptions(expenseTypes);
  }, [getExpenseTypes, currentInvoice.companyId]);

  const { control, formState, handleSubmit, getValues, watch, setError } =
    useForm<InvoiceDetails>({
      mode: 'onChange',
      defaultValues: getInvoiceDataDefaultValues(currentInvoice)
    });

  const [isFormValid, setIsFormValid] = useState<boolean>(true);

  const watchedVatBase = watch('vatBase');
  const watchedVatAmount = watch('vatAmount');
  const watchedInvoiceAmount = watch('invoiceAmount');

  const isInvoiceAmountValid = useMemo(() => {
    if (!watchedInvoiceAmount) {
      return false;
    }

    const total =
      (Number(watchedVatBase) || 0) + (Number(watchedVatAmount) || 0);
    return (
      (Number(watchedInvoiceAmount) || 0).toFixed(2) ===
      Number(total).toFixed(2)
    );
  }, [watchedVatBase, watchedVatAmount, watchedInvoiceAmount]);

  const handleVerifySection = () => {
    isVerifiedRef.current = true;
    setIsVerified(true);
    handleVerify(
      getInvoiceDataFormValues(
        currentInvoice,
        expenseTypeOptions || [],
        getValues
      ),
      InvoiceSection.INVOICE_DATA
    );
  };

  useEffect(() => {
    triggerRepromptRef.current = triggerReprompt;
  }, [triggerReprompt]);

  useEffect(() => {
    fetchCurrencies();
    fetchExpenseTypes();
  }, [fetchCurrencies, fetchExpenseTypes]);

  useEffect(() => {
    // TODO: CHANGE THIS TO WORK WITH REACT HOOK FORM !!!
    const invoiceNumber = getValues('invoiceNumber');
    const expenseType = getValues('expenseType');
    const dueDate = getValues('dueDate');
    const invoiceDate = getValues('invoiceDate');

    if (!invoiceNumber) {
      setError('invoiceNumber', {
        type: 'required',
        message: translate('errors.invoiceNumber')
      });
    }
    if (!expenseType) {
      setError('expenseType', {
        type: 'required',
        message: translate('errors.expenseType')
      });
    }

    if (!dueDate) {
      setError('dueDate', {
        type: 'required',
        message: translate('errors.dueDate')
      });
    }

    if (!invoiceDate) {
      setError('invoiceDate', {
        type: 'required',
        message: translate('errors.invoiceDate')
      });
    }
  }, [getValues, setError, translate]);

  useEffect(() => {
    setIsFormValid(formState.isValid);
  }, [formState.isValid]);

  useEffect(() => {
    const handleBeforeUnload = () =>
      handleSaveSection(
        getSaveInvoiceDataFormValues(currentInvoice, getValues),
        InvoiceSection.INVOICE_DATA
      );
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [handleSaveSection, getSaveInvoiceDataFormValues]);

  useEffect(() => {
    return () => {
      if (isVerifiedRef.current || triggerRepromptRef.current) {
        return;
      }

      handleSaveSection(
        getSaveInvoiceDataFormValues(currentInvoice, getValues),
        InvoiceSection.INVOICE_DATA
      );
    };
  }, [location.pathname, handleSaveSection, getSaveInvoiceDataFormValues]);

  return (
    <Box sx={{ pt: 2.5 }}>
      <form onSubmit={handleSubmit(handleVerifySection)}>
        <ActionButtons
          shouldVerify
          handleBack={() => handleBack(InvoiceSection.SUPPLIER)}
          isFormValid={isFormValid}
        />
        {currencies && expenseTypeOptions && (
          <Box sx={verificationFormFields}>
            <Box
              sx={{
                ...verificationFormFieldsWrapper,
                minHeight: '50px'
              }}
            >
              <FormControl sx={verificationFormItem}>
                <Controller
                  name="invoiceNumber"
                  control={control}
                  rules={{ required: true }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      error={!!error}
                      label={`${translate('labels.invoiceNumber')}*`}
                      name="invoiceNumber"
                      placeholder={translate('labels.invoiceNumber')}
                      sx={verificationFormItem}
                      helperText={error && translate('errors.invoiceNumber')}
                    />
                  )}
                />
              </FormControl>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <Controller
                  name="invoiceDate"
                  control={control}
                  rules={{ required: true }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error }
                  }) => {
                    return (
                      <FormControl error={!!error} sx={verificationFormItem}>
                        <DatePicker
                          sx={{ width: '100%' }}
                          format={DATE_FORMATS.displayedDateFormat}
                          open={isInvoiceDateOpen}
                          disableFuture
                          label={`${translate('labels.invoiceDate')}*`}
                          value={
                            value
                              ? moment(value, DATE_FORMATS.displayedDateFormat)
                              : null
                          }
                          onClose={() => setInvoiceDateOpen(false)}
                          onChange={(event) => {
                            onChange(
                              (event as Moment).format(
                                DATE_FORMATS.displayedDateFormat
                              )
                            );
                          }}
                          slotProps={{
                            textField: {
                              error: !!error,
                              helperText:
                                !!error && translate('errors.invoiceDate'),
                              name: 'invoiceDate',
                              onClick: () => setInvoiceDateOpen(true),
                              inputProps: { readOnly: true }
                            },
                            openPickerButton: {
                              onClick: () => setInvoiceDateOpen(true)
                            }
                          }}
                        />
                      </FormControl>
                    );
                  }}
                />

                <Controller
                  name="dueDate"
                  rules={{ required: true }}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error }
                  }) => {
                    return (
                      <FormControl error={!!error} sx={verificationFormItem}>
                        <DatePicker
                          sx={{ width: '100%' }}
                          format={DATE_FORMATS.displayedDateFormat}
                          open={isDueDateOpen}
                          label={`${translate('labels.dueDate')}*`}
                          value={
                            value
                              ? moment(value, DATE_FORMATS.displayedDateFormat)
                              : null
                          }
                          onClose={() => setDueDateOpen(false)}
                          onChange={(event) => {
                            onChange(
                              (event as Moment).format(
                                DATE_FORMATS.displayedDateFormat
                              )
                            );
                          }}
                          slotProps={{
                            textField: {
                              error: !!error,
                              helperText:
                                !!error && translate('errors.dueDate'),
                              name: 'dueDate',
                              onClick: () => setDueDateOpen(true),
                              inputProps: { readOnly: true }
                            },
                            openPickerButton: {
                              onClick: () => setDueDateOpen(true)
                            }
                          }}
                        />
                      </FormControl>
                    );
                  }}
                />
              </LocalizationProvider>
            </Box>
            <Box
              sx={{
                ...verificationFormFieldsWrapper,
                height: '70px'
              }}
            >
              <Controller
                name="expenseType"
                control={control}
                rules={{
                  required: translate('errors.expenseType')
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl error={!!error} sx={verificationFormItem}>
                    <InputLabel>{`${translate(
                      'labels.expenseType'
                    )}*`}</InputLabel>
                    <Select
                      {...field}
                      value={field.value || ''}
                      sx={{ textAlign: 'left' }}
                      label={`${translate('labels.expenseType')}*`}
                      error={!!error}
                      renderValue={(selected) => {
                        const selectedOption = expenseTypeOptions.find(
                          (expenseType) => expenseType.id === selected
                        ) as InvoiceExpenseType;

                        return (
                          <span
                            style={{
                              color:
                                selectedOption && !selectedOption.isActive
                                  ? 'gray'
                                  : 'inherit'
                            }}
                          >
                            {!selectedOption.isActive
                              ? `${selectedOption.name} ${translate(
                                  'labels.deactivatedExpenseTypeValue'
                                )}`
                              : selectedOption.name}
                          </span>
                        );
                      }}
                    >
                      {expenseTypeOptions &&
                        expenseTypeOptions.map(
                          (expenseType: InvoiceExpenseType) =>
                            expenseType.isActive && (
                              <MenuItem
                                key={expenseType.id}
                                value={expenseType.id}
                                disabled={!expenseType.isActive}
                              >
                                {expenseType.name}
                              </MenuItem>
                            )
                        )}
                    </Select>
                    <FormHelperText>
                      {error && translate('errors.expenseType')}
                    </FormHelperText>
                  </FormControl>
                )}
              />
              <Controller
                name="currencyId"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl
                    error={!!error}
                    sx={verificationFormItemNoHeight}
                  >
                    <InputLabel>{`${translate(
                      'labels.currency'
                    )}*`}</InputLabel>
                    <Select
                      {...field}
                      sx={{ textAlign: 'left' }}
                      label={`${translate('labels.currency')}*`}
                      error={!!error}
                    >
                      {currencies &&
                        currencies.map((currency: CurrencyDropDown) => (
                          <MenuItem key={currency.id} value={currency.id}>
                            {currency.isoCode}
                          </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>
                      {error && translate('errors.currency')}
                    </FormHelperText>
                  </FormControl>
                )}
              />
            </Box>
            <Box>
              <Box
                sx={{
                  ...verificationFormFieldsWrapper,
                  minHeight: '50px'
                }}
              >
                <Controller
                  name="vatBase"
                  control={control}
                  rules={{ required: true }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      onChange={(event) => {
                        field.onChange(
                          processMoneyInput(
                            event.target.value,
                            String(field.value)
                          )
                        );
                      }}
                      error={!!error}
                      label={`${translate('labels.vatBase')}*`}
                      name="vatBase"
                      placeholder={translate('labels.vatBase')}
                      sx={verificationFormItemNoHeight}
                      helperText={error && translate('errors.vatBase')}
                    />
                  )}
                />
                <Controller
                  name="vatAmount"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      onChange={(event) => {
                        field.onChange(
                          processMoneyInput(
                            event.target.value,
                            String(field.value)
                          )
                        );
                      }}
                      label={`${translate('labels.vatAmount')}*`}
                      name="vatAmount"
                      placeholder={translate('labels.vatAmount')}
                      sx={verificationFormItemNoHeight}
                    />
                  )}
                />
                <Controller
                  name="invoiceAmount"
                  control={control}
                  rules={{
                    required: true,
                    validate: () => isInvoiceAmountValid
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      onChange={(event) => {
                        field.onChange(
                          processMoneyInput(
                            event.target.value,
                            String(field.value)
                          )
                        );
                      }}
                      label={`${translate('labels.invoiceAmount')}*`}
                      name="invoiceAmount"
                      error={!isInvoiceAmountValid}
                      placeholder={translate('labels.invoiceAmount')}
                      sx={verificationFormItemNoHeight}
                    />
                  )}
                />
              </Box>
              <FormHelperText error={!isInvoiceAmountValid}>
                {!isInvoiceAmountValid &&
                  translate('errors.invoiceAmountEqual')}
              </FormHelperText>
            </Box>
          </Box>
        )}
      </form>
    </Box>
  );
};
