import { Box } from '@mui/material';
import { GridSortModel, GridPaginationModel } from '@mui/x-data-grid';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { useAtom } from 'jotai';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useInvoiceController } from 'api/controllers/InvoiceController';

import {
  Invoice,
  InvoiceFilters,
  InvoiceQueryFilters,
  OpenAPI,
  SortOrder
} from 'openapi';

import { InvoicesDataGrid } from 'components/InvoicesDataGrid/InvoicesDataGrid';
import { Layout } from 'components/Layout/Layout';

import { usePagination } from 'hooks/usePagination';

import { ACCESS_TOKEN, HEARTBEAT_TIMEOUT } from 'utils/constants/constants';
import { INITIAL_INVOICES_FILTERS } from 'utils/constants/invoices';
import { INITIAL_PAGINATION_PROPS } from 'utils/constants/paginations';
import { DocumentsType } from 'utils/enums/DocumentsType';
import { InvoiceEventType } from 'utils/enums/Invoice';
import { getInvoicesFiltersAtomByType } from 'utils/helpers/filtersHelpers';
import { handleSortModelChange } from 'utils/helpers/invoiceHelpers';
import {
  getEventData,
  getSSERequestOptions,
  getUrl
} from 'utils/helpers/serverSentEvents';
import { InvoicesFiltersForm } from 'utils/interfaces/InvoiceProps';
import {
  getMappedInvoiceFilters,
  getMappedInvoices
} from 'utils/mappers/invoice';
import { AppRoutesEnum } from 'utils/routes';

import { commonDataGridContainerStyle } from 'styles/components/DataGridStyle';
import { invoicesWrapper } from 'styles/pages/InvoicesStyle';

import {
  invoicesPaginationAtom,
  filterTypeAtom,
  isSavingAtom,
  userAtom
} from 'state/state';

interface CompanyDocumentsViewProps {
  documentsType: DocumentsType;
}

export const CompanyDocumentsView = ({
  documentsType
}: CompanyDocumentsViewProps) => {
  const {
    getFilteredInvoices,
    getInvoiceFiltersForAllCompanies,
    getInvoiceRow
  } = useInvoiceController();

  const [totalInvoices, setTotalInvoices] = useState<number>(0);

  const [filterType] = useAtom(filterTypeAtom);
  const [pagination, setPagination] = useAtom(invoicesPaginationAtom);
  const [filters, setFilters] = useAtom(
    getInvoicesFiltersAtomByType(filterType)
  );
  const [isSaving] = useAtom(isSavingAtom);
  const [user] = useAtom(userAtom);

  const { paginationModel, handlePaginationModelChange } = usePagination({
    page: pagination.page,
    pageSize: pagination.pageSize,
    rowCount: totalInvoices
  });

  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [invoicesFiltersData, setInvoicesFiltersData] = useState<
    InvoiceFilters & InvoiceQueryFilters
  >({});
  const [isFiltered, setIsFiltered] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const filtersRef = useRef(filters);

  const fetchInvoices = useCallback(async () => {
    const currentFilters = filtersRef.current;
    setIsLoading(true);
    const result = await getFilteredInvoices({
      ...currentFilters,
      pageNumber: paginationModel.page + 1,
      pageSize: paginationModel.pageSize,
      sortBy: currentFilters.sortBy,
      sortOrder: currentFilters.sortOrder?.toUpperCase() as SortOrder
    });

    const mappedInvoices = getMappedInvoices(result.invoices);

    setInvoices(mappedInvoices);
    setTotalInvoices(result.totalElements);
    setIsLoading(false);
  }, [paginationModel, getFilteredInvoices, filtersRef]);

  const fetchInvoiceFilters = useCallback(async () => {
    const filtersData = await getInvoiceFiltersForAllCompanies();
    setInvoicesFiltersData(filtersData);
  }, [getInvoiceFiltersForAllCompanies]);

  const token = useMemo(
    () => localStorage.getItem(ACCESS_TOKEN),
    [localStorage.getItem(ACCESS_TOKEN)]
  );

  const handleApplyFilters = useCallback(
    (data: InvoicesFiltersForm | null) => {
      const mappedFilters = getMappedInvoiceFilters(
        data || ({} as InvoicesFiltersForm)
      );
      setIsFiltered(!!data);
      // @ts-ignore
      setFilters({
        ...INITIAL_INVOICES_FILTERS,
        ...mappedFilters
      });
      handlePaginationModelChange({
        ...paginationModel,
        page: INITIAL_PAGINATION_PROPS.page
      });
    },
    [setFilters, paginationModel]
  );

  const handleSortChange = useCallback(
    (sortModel: GridSortModel) => {
      handleSortModelChange(sortModel, setFilters);
    },
    [setFilters]
  );

  const handlePagination = useCallback(
    (newPaginationModel: GridPaginationModel) => {
      setInvoices([]);
      handlePaginationModelChange(newPaginationModel);
    },
    [setInvoices, handlePaginationModelChange]
  );

  const handleInvoiceEvent = useCallback(
    async (data: string) => {
      const { eventId, eventType } = getEventData(data);
      const currentFilters = filtersRef.current;

      const shouldNotUpdate =
        eventType === InvoiceEventType.HEARTBEAT ||
        isFiltered ||
        paginationModel.page !== INITIAL_PAGINATION_PROPS.page ||
        currentFilters.sortBy !== INITIAL_INVOICES_FILTERS.sortBy ||
        currentFilters.sortOrder !== INITIAL_INVOICES_FILTERS.sortOrder;

      if (
        shouldNotUpdate &&
        eventType !== InvoiceEventType.INVOICE_DELETE &&
        eventType !== InvoiceEventType.INVOICE_DUPLICATE
      ) {
        return;
      }

      if (eventType === InvoiceEventType.INVOICE_CREATE) {
        const invoice = await getInvoiceRow(Number(eventId));
        const mappedInvoice = getMappedInvoices([invoice]);
        setInvoices((prevInvoices) => [...mappedInvoice, ...prevInvoices]);
        setTotalInvoices((prevTotalInvoices) => prevTotalInvoices + 1);
        return;
      }

      if (eventType === InvoiceEventType.INVOICE_UPDATE) {
        const invoice = await getInvoiceRow(Number(eventId));
        const mappedInvoice = getMappedInvoices([invoice]);
        setInvoices((prevInvoices) => {
          if (prevInvoices.some((inv) => inv.id === mappedInvoice[0].id)) {
            return prevInvoices.map((inv) =>
              inv.id === mappedInvoice[0].id ? mappedInvoice[0] : inv
            );
          }
          return [...mappedInvoice, ...prevInvoices];
        });
        return;
      }

      fetchInvoices();
    },
    [paginationModel, fetchInvoices, invoices, isFiltered]
  );

  useEffect(() => {
    filtersRef.current = filters;
  }, [filters]);

  useEffect(() => {
    fetchInvoiceFilters();
  }, [documentsType]);

  useEffect(() => {
    setPagination({
      page: paginationModel.page,
      pageSize: paginationModel.pageSize
    });
    if (!Object.keys(invoicesFiltersData).length || isSaving) {
      return;
    }
    fetchInvoices();
  }, [filters, paginationModel, isSaving, fetchInvoices]);

  useEffect(() => {
    let retryTimeout: NodeJS.Timeout | undefined;
    let heartbeatTimeout: NodeJS.Timeout | undefined;
    let retryCount = 0;

    const createEventSource = (): EventSourcePolyfill => {
      const result = new EventSourcePolyfill(
        getUrl(OpenAPI, getSSERequestOptions(user?.id)),
        {
          headers: {
            Authorization: `Bearer ${token}` || ''
          }
        }
      );

      result.onmessage = (e) => {
        const { eventType } = getEventData(e.data);

        if (eventType === InvoiceEventType.HEARTBEAT) {
          if (heartbeatTimeout) clearTimeout(heartbeatTimeout);

          heartbeatTimeout = setTimeout(() => {
            result.close();
            createEventSource();
          }, HEARTBEAT_TIMEOUT);
        } else {
          handleInvoiceEvent(e.data);
        }
      };

      result.onerror = () => {
        result.close();
        if (heartbeatTimeout) clearTimeout(heartbeatTimeout);
        if (retryTimeout) clearTimeout(retryTimeout);

        const retryDelay = Math.min(10000, 2 ** retryCount * 1000);
        retryCount += 1;

        retryTimeout = setTimeout(() => {
          createEventSource();
        }, retryDelay);
      };

      return result;
    };

    const eventSourceInstance = createEventSource();

    return () => {
      eventSourceInstance.close();
      if (heartbeatTimeout) clearTimeout(heartbeatTimeout);
      if (retryTimeout) clearTimeout(retryTimeout);
    };
  }, [token]);

  useEffect(() => {
    fetchInvoices();
  }, []);

  return (
    <Layout>
      <Box sx={invoicesWrapper}>
        <Box sx={commonDataGridContainerStyle}>
          <InvoicesDataGrid
            invoicesList={invoices}
            filters={filters}
            stateRoute={AppRoutesEnum.INVOICES}
            setInvoicesList={setInvoices}
            invoicesFiltersData={invoicesFiltersData}
            handleApplyFilters={handleApplyFilters}
            paginationModel={paginationModel}
            handlePaginationModelChange={handlePagination}
            rowCount={totalInvoices}
            handleSortModelChange={handleSortChange}
            fetchInvoices={fetchInvoices}
            isLoading={isLoading}
          />
        </Box>
      </Box>
    </Layout>
  );
};
