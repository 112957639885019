import PendingIcon from '@mui/icons-material/Pending';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import {
  Box,
  Paper,
  Typography,
  Chip,
  Divider,
  Tooltip,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import { useAdditionalFieldsController } from 'api/controllers/AdditionalFieldsController';
import { useInvoiceController } from 'api/controllers/InvoiceController';

import {
  ExpenseType,
  Invoice,
  InvoiceExpenseType,
  InvoiceStages
} from 'openapi';

import { ApprovalButtons } from 'components/forms/InvoiceVerificationForms/ApprovalButtons';
import { LabelValueField } from 'components/shared/LabelField/LabelValueField';
import { Modal } from 'components/shared/Modal/Modal';

import { usePermissions } from 'context/PermissionsContext';
import { useTranslations } from 'context/TranslationContext';

import { useModal } from 'hooks/useModal';

import { getApprovalStatus } from 'utils/helpers/approvalsHelpers';

import {
  approvedIconStyle,
  awaitingApprovalIconStyle,
  rejectedIconStyle
} from 'styles/components/ApprovalsStyle';
import {
  overviewTypographyStyle,
  verificationFormItem
} from 'styles/pages/InvoiceVerificationStyle';

interface ApprovalsProps {
  currentInvoice: Invoice;
  handleApprove: () => void;
  handleBack?: () => void;
  handleReject: () => void;
  handleReturnToApproved: () => void;
  handleReturnToValidated: () => void;
}

export const Approvals = ({
  currentInvoice,
  handleBack,
  handleApprove,
  handleReject,
  handleReturnToApproved,
  handleReturnToValidated
}: ApprovalsProps) => {
  const { translate } = useTranslations();
  const { getExpenseTypes } = useAdditionalFieldsController();
  const { updateExpenseTypeDuringApprovalById } = useInvoiceController();
  const { permissions } = usePermissions();

  const [selectedExpenseTypeId, setSelectedExpenseTypeId] = useState<
    number | null
  >(currentInvoice.expenseType?.id || null);
  const [expenseTypeOptions, setExpenseTypeOptions] = useState<ExpenseType[]>(
    []
  );

  const {
    isOpen: isCreateEditModalOpen,
    closeModal: closeCreateEditModal,
    openModal: openCreateEditModal
  } = useModal();

  const renderCounterpartyValue = (): string => {
    const {
      counterpartyName,
      counterpartyRegistrationNumber,
      counterpartyVatNumber
    } = currentInvoice;

    // Both registration number and VAT number are available
    if (counterpartyRegistrationNumber && counterpartyVatNumber) {
      return `${counterpartyName} (${counterpartyRegistrationNumber}, ${counterpartyVatNumber})`;
    }
    // Only registration number is available
    if (counterpartyRegistrationNumber) {
      return `${counterpartyName} (${counterpartyRegistrationNumber})`;
    }
    // Only VAT number is available
    if (counterpartyVatNumber) {
      return `${counterpartyName} (${counterpartyVatNumber})`;
    }
    // Neither registration number nor VAT number are available
    return counterpartyName as string;
  };

  // Expense Type Approval
  const {
    approvalText: expenseTypeApproval,
    approvalValue: expenseTypeApprovalValue
  } = getApprovalStatus(
    currentInvoice.isExpenseTypeApproved || false,
    currentInvoice.expenseTypeApprovedBy,
    currentInvoice.expenseTypeApprovers,
    translate
  );

  // Counterparty Approval
  const {
    approvalText: counterpartyApproval,
    approvalValue: counterpartyApprovalValue
  } = getApprovalStatus(
    currentInvoice.isCounterpartyApproved || false,
    currentInvoice.counterpartyApprovedBy,
    currentInvoice.counterpartyApprovers,
    translate
  );

  // Amount Approval
  const { approvalText: amountApproval, approvalValue: amountApprovalValue } =
    getApprovalStatus(
      currentInvoice.isAmountApproved || false,
      currentInvoice.amountApprovedBy,
      currentInvoice.amountApprovers,
      translate
    );

  const fetchExpenseTypes = useCallback(async () => {
    const expenseTypeOptionValues = await getExpenseTypes(
      Number(currentInvoice.companyId)
    );
    setExpenseTypeOptions(expenseTypeOptionValues);
  }, [getExpenseTypes, currentInvoice]);

  const handleSaveExpenseType = useCallback(async () => {
    if (
      !selectedExpenseTypeId ||
      !currentInvoice.companyId ||
      !currentInvoice.id
    ) {
      return;
    }

    await updateExpenseTypeDuringApprovalById(
      currentInvoice.companyId,
      currentInvoice.id,
      selectedExpenseTypeId
    );
    closeCreateEditModal();
  }, [
    selectedExpenseTypeId,
    updateExpenseTypeDuringApprovalById,
    closeCreateEditModal
  ]);

  useEffect(() => {
    if (expenseTypeOptions.length) {
      return;
    }

    fetchExpenseTypes();
  }, [fetchExpenseTypes]);

  useEffect(() => {
    setSelectedExpenseTypeId(currentInvoice.expenseType?.id || null);
  }, [currentInvoice]);

  return (
    <Box sx={{ width: '100%' }}>
      <ApprovalButtons
        handleReject={handleReject}
        handleBack={handleBack}
        handleApprove={handleApprove}
        handleReturnToApproved={handleReturnToApproved}
        handleReturnToValidated={handleReturnToValidated}
        currentInvoice={currentInvoice}
      />

      <Paper
        elevation={3}
        sx={{ padding: 1, backgroundColor: '#fefefe', marginTop: 2 }}
      >
        {/* Receiver Information */}
        <LabelValueField
          label={translate('labels.receiver')}
          value={currentInvoice.shortNameCompany || '-'}
        />

        <LabelValueField
          label={translate('labels.counterparty')}
          value={renderCounterpartyValue()}
        />

        <LabelValueField
          label={translate('labels.documentType')}
          value={currentInvoice.documentType || '-'}
        >
          {currentInvoice.isAlreadyPaid && (
            <Chip
              color="success"
              label={translate('labels.paid')}
              sx={{
                height: 18,
                fontSize: '0.75rem'
              }}
            />
          )}
        </LabelValueField>

        <Divider sx={{ my: 2 }} />
        {/* Document and Payment Status */}

        <LabelValueField
          label={translate('labels.invoiceNumberAndDate')}
          value={`${currentInvoice.invoiceNumber || '-'} / ${
            currentInvoice.invoiceDate
          }`}
        />

        <LabelValueField
          label={translate('labels.expenseType')}
          value={
            currentInvoice.expenseType?.isActive
              ? currentInvoice.expenseType?.name || '-'
              : `${currentInvoice.expenseType?.name} ${translate(
                  'labels.deactivatedExpenseTypeValue'
                )}`
          }
        >
          {currentInvoice.stage === InvoiceStages.VALIDATED &&
            permissions.INVOICES.update && (
              <Button
                sx={{
                  fontSize: '0.7rem',
                  padding: '0rem 0.5rem'
                }}
                variant="outlined"
                size="small"
                onClick={openCreateEditModal}
              >
                {translate('buttons.change')}
              </Button>
            )}
        </LabelValueField>

        <LabelValueField
          label={translate('labels.totalAmount')}
          value={`${currentInvoice.invoiceAmount?.toFixed(2)} ${
            currentInvoice.currency
          } (${translate('labels.vatBase')}: ${
            currentInvoice.vatBase?.toFixed(2) || 0
          } ${currentInvoice.currency})`}
        />
      </Paper>

      {currentInvoice.stage !== InvoiceStages.REJECTED ? (
        <Paper
          elevation={3}
          sx={{ padding: 1, backgroundColor: '#fefefe', marginTop: 2 }}
        >
          {currentInvoice.isExpenseTypeApproved !== null && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                mb: 1
              }}
            >
              {currentInvoice.isExpenseTypeApproved ? (
                <ThumbUpAltIcon sx={approvedIconStyle} />
              ) : (
                <PendingIcon sx={awaitingApprovalIconStyle} />
              )}
              <Box>
                <Typography
                  sx={{
                    fontSize: '0.9rem',
                    fontWeight: 600,
                    textAlign: 'left'
                  }}
                >
                  {translate('labels.expenseType')}
                </Typography>
                <Divider sx={{ my: 0.2 }} />
                <Tooltip title={expenseTypeApprovalValue}>
                  <Typography
                    sx={{ ...overviewTypographyStyle, textAlign: 'left' }}
                  >
                    {expenseTypeApproval}
                  </Typography>
                </Tooltip>
              </Box>
            </Box>
          )}

          {currentInvoice.isCounterpartyApproved !== null && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                mb: 1
              }}
            >
              {currentInvoice.isCounterpartyApproved ? (
                <ThumbUpAltIcon sx={approvedIconStyle} />
              ) : (
                <PendingIcon sx={awaitingApprovalIconStyle} />
              )}

              <Box>
                <Typography
                  sx={{
                    fontSize: '0.9rem',
                    fontWeight: 600,
                    textAlign: 'left'
                  }}
                >
                  {translate('labels.counterparty')}
                </Typography>
                <Divider sx={{ my: 0.2 }} />
                <Tooltip title={counterpartyApprovalValue}>
                  <Typography
                    sx={{ ...overviewTypographyStyle, textAlign: 'left' }}
                  >
                    {counterpartyApproval}
                  </Typography>
                </Tooltip>
              </Box>
            </Box>
          )}

          {currentInvoice.isAmountApproved !== null && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              {currentInvoice.isAmountApproved ? (
                <ThumbUpAltIcon sx={approvedIconStyle} />
              ) : (
                <PendingIcon sx={awaitingApprovalIconStyle} />
              )}
              <Box>
                <Typography
                  sx={{
                    fontSize: '0.9rem',
                    fontWeight: 600,
                    textAlign: 'left'
                  }}
                >
                  {translate('labels.amount')}
                </Typography>
                <Divider sx={{ my: 0.2 }} />
                <Tooltip title={amountApprovalValue}>
                  <Typography
                    sx={{ ...overviewTypographyStyle, textAlign: 'left' }}
                  >
                    {amountApproval}
                  </Typography>
                </Tooltip>
              </Box>
            </Box>
          )}
        </Paper>
      ) : (
        <Paper
          elevation={3}
          sx={{ padding: 1, backgroundColor: '#fefefe', marginTop: 2 }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <ThumbDownAltIcon sx={rejectedIconStyle} />
            <Box>
              <Typography
                sx={{ fontSize: '0.9rem', fontWeight: 600, textAlign: 'left' }}
              >
                {translate('labels.rejectedBy')}
              </Typography>
              <Divider sx={{ my: 0.2 }} />
              <Tooltip title={expenseTypeApprovalValue}>
                <Typography
                  sx={{ ...overviewTypographyStyle, textAlign: 'left' }}
                >
                  {currentInvoice.rejectedBy}
                </Typography>
              </Tooltip>
            </Box>
          </Box>
        </Paper>
      )}
      <Modal
        headerTitle={translate('labels.expenseTypeEdit')}
        isOpen={isCreateEditModalOpen}
        hide={closeCreateEditModal}
        maxWidth="sm"
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <FormControl sx={verificationFormItem}>
            <InputLabel>{`${translate('labels.expenseType')}*`}</InputLabel>
            <Select
              value={selectedExpenseTypeId || ''}
              sx={{ textAlign: 'left' }}
              label={`${translate('labels.expenseType')}*`}
              onChange={(e) => setSelectedExpenseTypeId(Number(e.target.value))}
              renderValue={(selected) => {
                const selectedOption = expenseTypeOptions.find(
                  (expenseType) => expenseType.id === selected
                ) as InvoiceExpenseType;

                return (
                  <span
                    style={{
                      color:
                        selectedOption && !selectedOption.isActive
                          ? 'gray'
                          : 'inherit'
                    }}
                  >
                    {!selectedOption.isActive
                      ? `${selectedOption.name} ${translate(
                          'labels.deactivatedExpenseTypeValue'
                        )}`
                      : selectedOption.name}
                  </span>
                );
              }}
            >
              {expenseTypeOptions &&
                expenseTypeOptions.map(
                  (expenseType: ExpenseType) =>
                    expenseType.isActive && (
                      <MenuItem
                        key={expenseType.id}
                        value={expenseType.id}
                        disabled={!expenseType.isActive}
                      >
                        {expenseType.name}
                      </MenuItem>
                    )
                )}
            </Select>
          </FormControl>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={handleSaveExpenseType} variant="contained">
              {translate('buttons.save')}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};
