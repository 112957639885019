import { Box, Button, Tooltip } from '@mui/material';
import { useAtom } from 'jotai';
import { useMemo } from 'react';

import { Invoice, InvoiceStages } from 'openapi';

import { ConfirmationDialog } from 'components/shared/Modal/ConfirmationDialog';

import { usePermissions } from 'context/PermissionsContext';
import { useTranslations } from 'context/TranslationContext';

import { useModal } from 'hooks/useModal';

import { getApprovalModalContent } from 'utils/helpers/approvalsHelpers';

import { userAtom } from 'state/state';

interface ApprovalButtonsProps {
  currentInvoice: Invoice;
  handleApprove: () => void;
  handleBack?: () => void;
  handleReject: () => void;
  handleReturnToApproved: () => void;
  handleReturnToValidated: () => void;
}

export const ApprovalButtons = ({
  currentInvoice,
  handleBack,
  handleApprove,
  handleReject,
  handleReturnToApproved,
  handleReturnToValidated
}: ApprovalButtonsProps) => {
  const { translate } = useTranslations();
  const { isOpen, openModal, closeModal } = useModal();
  const {
    isOpen: isRejectModalOpen,
    openModal: openRejectModal,
    closeModal: closeRejectModal
  } = useModal();
  const {
    isOpen: isUnPayModalOpen,
    openModal: openUnPayModal,
    closeModal: closeUnPayModal
  } = useModal();
  const { permissions } = usePermissions();
  const [user] = useAtom(userAtom);

  const isInvoiceApproved = useMemo(
    () => currentInvoice.stage === InvoiceStages.APPROVED,
    [currentInvoice.stage]
  );

  const isInvoicePaid = useMemo(
    () => currentInvoice.stage === InvoiceStages.PAID,
    [currentInvoice.stage]
  );

  const isInvoiceRejected = useMemo(
    () => currentInvoice.stage === InvoiceStages.REJECTED,
    [currentInvoice.stage]
  );

  const isApproveButtonDisabled = useMemo(
    () =>
      !permissions.INVOICE_APPROVAL.update ||
      (!currentInvoice.invoiceApprovalNeeded?.isAmount &&
        !currentInvoice.invoiceApprovalNeeded?.isExpenseType &&
        !currentInvoice.invoiceApprovalNeeded?.isCounterparty),
    [currentInvoice, permissions.INVOICE_APPROVAL]
  );

  const isReturnToApproveButtonDisabled = useMemo(
    () =>
      !permissions.INVOICE_PAYMENTS.update ||
      !currentInvoice.expenseType?.approvers.find(
        (approver) => approver.id === user?.id
      ),
    [currentInvoice, permissions.INVOICE_PAYMENTS]
  );

  const approveContent = getApprovalModalContent(
    isInvoiceApproved,
    currentInvoice,
    translate
  );

  const shouldShowReturnToDraft = useMemo(
    () => currentInvoice.stage === InvoiceStages.VALIDATED,
    [currentInvoice.stage]
  );

  return (
    <Box
      sx={{
        pt: 2.5,
        display: 'flex',
        justifyContent: handleBack ? 'space-between' : 'flex-end',
        alignItems: 'center'
      }}
    >
      {handleBack && (
        <Tooltip
          title={
            isInvoicePaid || isInvoiceApproved || isInvoiceRejected
              ? translate('messages.approvalsBackButtonDisabled')
              : ''
          }
        >
          <span>
            <Button
              disabled={isInvoiceApproved || isInvoiceRejected || isInvoicePaid}
              onClick={handleBack}
              variant={shouldShowReturnToDraft ? 'contained' : 'outlined'}
              color={shouldShowReturnToDraft ? 'warning' : 'primary'}
            >
              {shouldShowReturnToDraft
                ? translate('labels.returnToDraft')
                : translate('buttons.back')}
            </Button>
          </span>
        </Tooltip>
      )}
      <Box sx={{ display: 'flex', gap: 2 }}>
        {currentInvoice.stage === InvoiceStages.VALIDATED ? (
          <Tooltip
            title={
              !currentInvoice.canReject &&
              translate('messages.unauthorizedToPerformAction')
            }
          >
            <span>
              <Button
                onClick={openRejectModal}
                variant="contained"
                disabled={!currentInvoice.canReject}
                color="primary"
              >
                {translate('buttons.reject')}
              </Button>
            </span>
          </Tooltip>
        ) : null}
        {currentInvoice.stage === InvoiceStages.VALIDATED ? (
          <Tooltip
            title={
              isApproveButtonDisabled &&
              translate('messages.unauthorizedToPerformAction')
            }
          >
            <span>
              <Button
                onClick={openModal}
                variant="contained"
                disabled={isApproveButtonDisabled}
                color="primary"
              >
                {translate('buttons.approve')}
              </Button>
            </span>
          </Tooltip>
        ) : null}
        {currentInvoice.stage === InvoiceStages.APPROVED ||
        currentInvoice.stage === InvoiceStages.REJECTED ? (
          <Tooltip
            title={
              !currentInvoice.canReturnToValidated &&
              translate('messages.unauthorizedToPerformAction')
            }
          >
            <span>
              <Button
                onClick={
                  currentInvoice.stage === InvoiceStages.REJECTED
                    ? openRejectModal
                    : openModal
                }
                variant="contained"
                disabled={!currentInvoice.canReturnToValidated}
                color="warning"
              >
                {translate('buttons.returnToValidated')}
              </Button>
            </span>
          </Tooltip>
        ) : null}
        {currentInvoice.stage === InvoiceStages.PAID ? (
          <Tooltip
            title={
              isReturnToApproveButtonDisabled &&
              translate('messages.unauthorizedToPerformAction')
            }
          >
            <span>
              <Button
                onClick={openUnPayModal}
                variant="contained"
                disabled={isReturnToApproveButtonDisabled}
                color={isInvoicePaid ? 'warning' : 'primary'}
              >
                {isInvoicePaid && translate('buttons.returnToApproved')}
              </Button>
            </span>
          </Tooltip>
        ) : null}
      </Box>
      <ConfirmationDialog
        isOpen={isOpen}
        onClose={closeModal}
        onConfirm={() => {
          isInvoiceApproved ? handleReturnToValidated() : handleApprove();
          closeModal();
        }}
        title={
          isInvoiceApproved
            ? translate('buttons.returnToValidated')
            : translate('labels.approveInvoice')
        }
        size="sm"
      >
        {isInvoiceApproved ? (
          translate('messages.returnInvoiceConfirmation')
        ) : (
          <div style={{ whiteSpace: 'pre-line' }}>{approveContent}</div>
        )}
      </ConfirmationDialog>
      <ConfirmationDialog
        isOpen={isRejectModalOpen}
        onClose={closeRejectModal}
        onConfirm={() => {
          isInvoiceRejected ? handleReturnToValidated() : handleReject();
          closeRejectModal();
        }}
        title={
          isInvoiceRejected
            ? translate('buttons.returnToValidated')
            : translate('buttons.reject')
        }
        size="sm"
      >
        {isInvoiceRejected
          ? translate('messages.returnInvoiceConfirmation')
          : translate('messages.rejectInvoiceConfirmation')}
      </ConfirmationDialog>
      <ConfirmationDialog
        isOpen={isUnPayModalOpen}
        onClose={closeUnPayModal}
        onConfirm={() => {
          handleReturnToApproved();
          closeUnPayModal();
        }}
        title={isInvoicePaid ? translate('buttons.returnToApproved') : ''}
        size="sm"
      >
        {isInvoicePaid
          ? translate('messages.returnInvoiceToApprovedConfirmation')
          : ''}
      </ConfirmationDialog>
    </Box>
  );
};
