import { Suspense, useMemo } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

import { Approvals } from 'pages/Approvals/Approvals';
import { Budgeting } from 'pages/Budgeting/Budgeting';
import { AddCompany } from 'pages/Companies/AddCompany';
import { CompanyDetails } from 'pages/Companies/CompanyDetails';
import { CompanyDocumentsView } from 'pages/CompanyDocumentsView/CompanyDocumentsView';
import { CounterParties } from 'pages/CounterParties/CounterParties';
import { Dashboard } from 'pages/Dashboard.tsx/Dashboard';
import { DocumentsUpload } from 'pages/DocumentsUpload/DocumentsUpload';
import { ExpenseTypesDataGrid } from 'pages/ExpenseTypes/ExpenseTypesDataGrid';
import { Payroll } from 'pages/Payroll/Payroll';
import { UserManagement } from 'pages/UserManagement/UserManagement';

import { InvoiceVerification } from 'components/forms/InvoiceVerificationStepper/InvoiceVerification';
import { ProtectedRoute } from 'components/Navigation/ProtectedRoute';
import { Loader } from 'components/shared/Loader/Loader';

import { useCompanies } from 'context/CompanyContext';
import { usePermissions } from 'context/PermissionsContext';

import { useRouteSecurity } from 'hooks/useRouteSecurity';

import { DocumentsType } from 'utils/enums/DocumentsType';
import { AppRoutesEnum } from 'utils/routes';

export const AppRoutes = () => {
  const { getHomePageRoute } = useRouteSecurity();
  const { companiesList, isCompaniesListLoading } = useCompanies();
  const { permissions } = usePermissions();
  const location = useLocation();

  const homePageRoute = useMemo(() => {
    return location.pathname === AppRoutesEnum.HOME
      ? getHomePageRoute()
      : AppRoutesEnum.HOME;
  }, [getHomePageRoute, location.pathname, companiesList]);

  return (
    permissions &&
    !isCompaniesListLoading && (
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route
            path={AppRoutesEnum.HOME}
            element={
              <ProtectedRoute path={AppRoutesEnum.HOME}>
                <Navigate to={homePageRoute} replace />
              </ProtectedRoute>
            }
          />
          <Route
            path={AppRoutesEnum.INVOICES}
            element={
              <ProtectedRoute path={AppRoutesEnum.INVOICES}>
                <CompanyDocumentsView documentsType={DocumentsType.INVOICES} />
              </ProtectedRoute>
            }
          />

          <Route
            path={AppRoutesEnum.INVOICES_APPROVAL}
            element={
              <ProtectedRoute path={AppRoutesEnum.INVOICES_APPROVAL}>
                <CompanyDocumentsView documentsType={DocumentsType.INVOICES} />
              </ProtectedRoute>
            }
          />

          <Route
            path={AppRoutesEnum.INVOICES_ACCOUNTING}
            element={
              <ProtectedRoute path={AppRoutesEnum.INVOICES_ACCOUNTING}>
                <CompanyDocumentsView documentsType={DocumentsType.INVOICES} />
              </ProtectedRoute>
            }
          />

          <Route
            path={AppRoutesEnum.INVOICES_PAYMENT}
            element={
              <ProtectedRoute path={AppRoutesEnum.INVOICES_PAYMENT}>
                <CompanyDocumentsView documentsType={DocumentsType.INVOICES} />
              </ProtectedRoute>
            }
          />

          <Route
            path={AppRoutesEnum.COMPANY_INVOICES}
            element={
              <ProtectedRoute path={AppRoutesEnum.COMPANY_INVOICES}>
                <DocumentsUpload />
              </ProtectedRoute>
            }
          />

          <Route
            path={AppRoutesEnum.COMPANY_INVOICES_APPROVAL}
            element={
              <ProtectedRoute path={AppRoutesEnum.COMPANY_INVOICES_APPROVAL}>
                <DocumentsUpload />
              </ProtectedRoute>
            }
          />
          <Route
            path={AppRoutesEnum.COMPANY_INVOICES_ACCOUNTING}
            element={
              <ProtectedRoute path={AppRoutesEnum.COMPANY_INVOICES_ACCOUNTING}>
                <DocumentsUpload />
              </ProtectedRoute>
            }
          />
          <Route
            path={AppRoutesEnum.COMPANY_INVOICES_PAYMENT}
            element={
              <ProtectedRoute path={AppRoutesEnum.COMPANY_INVOICES_PAYMENT}>
                <DocumentsUpload />
              </ProtectedRoute>
            }
          />

          <Route
            path={AppRoutesEnum.DASHBOARD}
            element={
              <ProtectedRoute path={AppRoutesEnum.DASHBOARD}>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path={AppRoutesEnum.COMPANY_DASHBOARD}
            element={
              <ProtectedRoute path={AppRoutesEnum.COMPANY_DASHBOARD}>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path={AppRoutesEnum.COMPANY_COUNTERPARTIES}
            element={
              <ProtectedRoute path={AppRoutesEnum.COMPANY_COUNTERPARTIES}>
                <CounterParties />
              </ProtectedRoute>
            }
          />
          <Route
            path={AppRoutesEnum.COMPANY_COUNTERPARTIES_NOT_TRUSTED}
            element={
              <ProtectedRoute
                path={AppRoutesEnum.COMPANY_COUNTERPARTIES_NOT_TRUSTED}
              >
                <CounterParties />
              </ProtectedRoute>
            }
          />
          <Route
            path={AppRoutesEnum.COMPANY_EXPENSE_TYPES}
            element={
              <ProtectedRoute path={AppRoutesEnum.COMPANY_EXPENSE_TYPES}>
                <ExpenseTypesDataGrid />
              </ProtectedRoute>
            }
          />
          <Route
            path={AppRoutesEnum.COMPANY_APPROVALS}
            element={
              <ProtectedRoute path={AppRoutesEnum.COMPANY_APPROVALS}>
                <Approvals />
              </ProtectedRoute>
            }
          />
          <Route
            path={AppRoutesEnum.COMPANY_BUDGETING}
            element={
              <ProtectedRoute path={AppRoutesEnum.COMPANY_BUDGETING}>
                <Budgeting />
              </ProtectedRoute>
            }
          />
          <Route
            path={AppRoutesEnum.COMPANY_PAYROLL}
            element={
              <ProtectedRoute path={AppRoutesEnum.COMPANY_PAYROLL}>
                <Payroll />
              </ProtectedRoute>
            }
          />
          <Route
            path={AppRoutesEnum.INVOICE_VERIFICATION}
            element={
              <ProtectedRoute path={AppRoutesEnum.INVOICE_VERIFICATION}>
                <InvoiceVerification />
              </ProtectedRoute>
            }
          />

          <Route
            path={AppRoutesEnum.COMPANY_DETAILS}
            element={
              <ProtectedRoute path={AppRoutesEnum.COMPANY_DETAILS}>
                <CompanyDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path={AppRoutesEnum.ADD_COMPANY}
            element={
              <ProtectedRoute path={AppRoutesEnum.ADD_COMPANY}>
                <AddCompany />
              </ProtectedRoute>
            }
          />

          <Route
            path={AppRoutesEnum.USER_MANAGEMENT}
            element={
              <ProtectedRoute path={AppRoutesEnum.USER_MANAGEMENT}>
                <UserManagement />
              </ProtectedRoute>
            }
          />

          <Route
            path={AppRoutesEnum.COMPANY_USER_MANAGEMENT}
            element={
              <ProtectedRoute path={AppRoutesEnum.COMPANY_USER_MANAGEMENT}>
                <UserManagement />
              </ProtectedRoute>
            }
          />

          <Route path="*" element={<Navigate to={homePageRoute} replace />} />
        </Routes>
      </Suspense>
    )
  );
};
